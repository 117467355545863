import { defaultHeaders, genericDestroy, url } from "./api.utils";
export const PoiAPI = {
	urls:{
		all:`${url}poi/all`
	},
	create: async (body) => {
		try {
			const res = await fetch(`${url}poi/create`, {
				method: "POST",
				headers: defaultHeaders,
				body: JSON.stringify(body),
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	update: async (id,body) => {
		try {
			console.log(body);
			const res = await fetch(`${url}poi/update/${id}`, {
				method: "PUT",
				headers: defaultHeaders,
				body: JSON.stringify(body),
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	getOne: async (id) => {
		try {
			const res = await fetch(`${url}poi/detail/${id}`, {
				method: "GET",
				headers: defaultHeaders,
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	destroy: async (id) => {
		return await genericDestroy("/poi/delete", id);
	},
	allTypes: async () => {
		try {
			const res = await fetch(`${url}poi_type/all`, {
				method: "GET",
				headers: defaultHeaders,
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	}
};

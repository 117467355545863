const { url, genericGet, defaultHeaders } = require("./api.utils");
const BASE_TRANSPORT_ENDPOINT = "transport";
/**
 *
 * @param {*} id The transport Id
 */
const findOne = async (id, isDataForEdition = false) => {
	try {
		const res = await genericGet(`city/transport/one/${id}`, {
			picture: true,
			shapes: !isDataForEdition,
			intermediate: false,
		});
		return res;
	} catch (error) {
		console.log(error);
	}
};
export const TransportAPI = {
	urls: {
		all: `${url}city/transport/all?picture=false&shapes=false&intermediate=false&type_id=1&statusArray=["PENDIENTE","ACEPTADO","RECHAZADO"]`,
	},
	findOne,
	findOneForEdition: (id) => findOne(id, true),
	listAllOfType: async (type_id) => {
		try {
			const res = await genericGet(`city/transport/all`, {
				type_id,
				statusArray: '["PENDIENTE","ACEPTADO","RECHAZADO"]',
			});

			return res;
		} catch (error) {
			console.log(error);
		}
	},
	update: async (id, body) => {
		try {
			console.log({ update: "update", id, body });
			const res = await fetch(`${url}city/transport/update/${id}`, {
				method: "PUT",
				headers: defaultHeaders,
				body: JSON.stringify(body),
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	updateV3: async ({ id, name, pictures, routeId }) => {
		try {
			const body = { name, pictures, routeId };
			const res = await fetch(`${url}${BASE_TRANSPORT_ENDPOINT}/${id}`, {
				method: "PATCH",
				headers: defaultHeaders,
				body: JSON.stringify(body),
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
};

import { Switch, Route, Redirect } from "react-router-dom"

// components
import Sidebar from "layouts/admin/components/Sidebar/Sidebar.js"
import AdminNavbar from "components/Navbars/AdminNavbar.js"
import HeaderStats from "components/Headers/HeaderStats.js"
import FooterAdmin from "components/Footers/FooterAdmin.js"

// views
import Maps from "views/admin/Maps.js"
import Settings from "views/admin/Settings.js"
import Dashboard from "views/admin/Dashboard.js"

import Places from 'views/admin/Places/Places'

import ReportViews from 'views/admin/Reports/reportViews'
import ReportZones from 'views/admin/Reports/Zones/ZonesReport'
import reportDestiny from 'views/admin/Reports/reportDestiny'

import Advertising from 'views/admin/Advertising'
import Category from 'views/admin/Category/Category'
import Contributions from "views/admin/Contributions/Contributions"
import  Transports  from "views/admin/Transports/Transports"
import { MapViewContextProvider } from "contexts/MapViewContext"
import { TransportReport } from "views/admin/Reports/Transport/TransportReport"
import { StopReport } from "views/admin/Reports/Stop/StopReport"
import { PoiReport } from "views/admin/Reports/Poi/PoiReport"
import { GTFSFileUploads } from "views/admin/GTFSFileUploads/GTFSFileUploads"
const adminRoutes = [
  ["dashboard", Dashboard],

  ["categorias", Category],
  ["Lugares", Places],
  ["micros", Maps],
  ["taxis", Maps],
  ["contribuciones", Contributions],
  ["publicidad", Advertising],
  ["transportes", Transports],
  ["gtfs", GTFSFileUploads],
  // Reportes
  ["reportes/zonas", ReportZones],
  ["reportes/visitas", ReportViews],
  ["reportes/destinos", reportDestiny],
  ["reportes/transportes", TransportReport],
  ["reportes/paradas", StopReport],
  ["reportes/lugares", PoiReport],
]

const Admin = () => {
  const loggedIn = sessionStorage.getItem('user') != null

  return <MapViewContextProvider>
    <Sidebar />
    <div className="relative md:ml-64 bg-blueGray-100">
      <AdminNavbar />
      <HeaderStats />
      <div className="px-4 md:px-10 mx-auto w-full -m-24">
      
        <Switch>
          {adminRoutes.map(([path, component], key) =>
            <Route exact key={key} path={`/admin/${path}`} 
            component={loggedIn ? component : ()=><Redirect to="/auth" />}>
              
            </Route>
          )}
          <Redirect from="/admin" to="/admin/dashboard" />
        </Switch>
        <FooterAdmin />
      </div>
    </div>
  </MapViewContextProvider>
}

export default Admin

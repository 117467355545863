import { Accordion, AccordionSummary, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { gtfsAPI } from "api/gtfs.api";
import { useEffect, useState } from "react";

export default function GTFSUploader() {


    const [formData,setFormData]=useState(new FormData())
    const [age, setAge] = useState('');
    const [city, setCity] = useState('');
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const handlers={
        input:(e)=>{
            const data = new FormData();
            
            const gtfsData = e.target.files[0];
            console.log(gtfsData);
            data.append('gtfs', gtfsData);
            data.append('name', 'esteli');
            data.append('originalname', 'esteliCITY');
            data.append('country_id',277);
            
            setFormData(data);
        },
        uploadGTFS:async()=>{
            console.log('uploading',{formData:formData.entries()});
            for(var pair of formData.entries()) {
                console.log(pair[0]+ ', '+ pair[1]);
             }
            const res=await gtfsAPI.sendFile(formData) 
            console.log(res);
        }
    }
    const handleChange = (event) => {
           
        setAge(event.target.value);

    };

    const handleChange2 = (event) => { setCity(event.target.value); }

    const  uploadAction=()=>{}

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}country`)
            .then(response => response.json())
            .then(data => setCountries(data))
    }, [])

    return <>
        <Accordion>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="h6" style={{ paddingLeft: '24px' }}>GTFS <i className="fas fa-upload"></i></Typography>
            </AccordionSummary>
            <div style={{ padding: '24px', flexDirection: 'column', display: 'flex', gap: '8px' }}>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">País</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        onChange={handleChange}
                        onClick={async () => {
                            if (age) {
                                fetch(`${process.env.REACT_APP_API}country/cities?country_id=${age}`)
                                    .then(response => response.json())
                                    .then(data => setCities(data.data.Cities))
                            }
                        }}
                    >
                        {countries.map(option => {
                            return (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.country_name}
                                </MenuItem>
                            )
                        })}
                    </Select>

                </FormControl>
                <FormControl>
                    <InputLabel id="demo-simple-select-label2">Ciudad</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={city}
                        onChange={handleChange2}
                    >
                        {cities.map(option => {
                            return (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.city_name}
                                </MenuItem>
                            )
                        })}
                    </Select>

                </FormControl>
                <input  onInput={handlers.input}
                        type="file"
                        //hidden
                    />
                <Button
                    variant="contained"
                    component="label"
                    onClick={handlers.uploadGTFS}
                >
                    Subir Archivo
                    
                </Button>

            </div>
        </Accordion>
    </>
}

import { Grid, Button } from '@material-ui/core'

import DataTable from './Containers/DataTableContainer'

import { Add } from '@material-ui/icons'
import { useModal, useFetch } from '../../../hooks/useState'
import { useGlobalDialog } from 'hooks/useGlobalDialog'
import { handleFormChangeStateNamed } from 'utils/formUtils'
import { DialogContainer } from './Containers/DialogContainer'
import { PoiTypeAPI } from 'api/poiTypes.api'

const Tables = () => {
    const globalDialog=useGlobalDialog();
   
    const { rows, reloadPage } = useFetch(PoiTypeAPI.urls.all)
    const handlers={
        clickNew:()=>{
            globalDialog.showWidget(()=><DialogContainer type='create' reloadData={reloadPage}/>);
        }
    }
    const model={};
    return <>
        <Grid container spacing={1} direction="row">
            <Grid item>
                <Button
                  disableElevation
                    color="primary"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handlers.clickNew}
                >Nueva Categoria
                </Button>
            </Grid>
            <Grid item xs={12}>
                <DataTable rows={rows.data} reloadPage={reloadPage} />
            </Grid>
        </Grid>
        
    </>
}

export default Tables
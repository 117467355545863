import React from "react";
import Chart from "chart.js";

import { app } from 'assets/json/views.json'

export default function CardBarChart() {
  React.useEffect(() => {
    let config = {
      type: "bar",
      data: {
        labels: app.map(({ hour }) => hour),
        datasets: [
          {
            label: new Date().getFullYear(),
            backgroundColor: "#ed64a6",
            borderColor: "#ed64a6",
            data: [],
            fill: false,
            barThickness: 8,
          },
          {
            label: new Date().getFullYear() - 1,
            fill: false,
            backgroundColor: "#fff",
            borderColor: "#ffff",
            data: app.map(({ views }) => views),
            barThickness: 8,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "#ffff",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              display: false,
              scaleLabel: {
                fontColor:"#FFFF",
                display: false,
               
                labelString: "Month",
              },color:"#FFFF", 
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(213, 213, 213, 0.5)",
                zeroLineColor: "rgba(213, 213, 213, 0.5)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
                
              },ticks: {
                fontColor: "white",
             }
            },
          ],
          yAxes: [
            {color:"#FFFF",
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(213, 213, 213, 0.5)",
                zeroLineColor: "rgba(213, 213, 213, 0.5)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },ticks: {
                fontColor: "white",
             }
            },
          ],
        },
      },
    };
    let ctx = document.getElementById("bar-chart").getContext("2d");
    window.myBar = new Chart(ctx, config);
  }, []);
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-600 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-white mb-1 text-xs font-semibold">
              Performance
              </h6>
            <h2 className="text-white text-xl font-semibold">
              Total orders
              </h2>
          </div>
        </div>
      </div>
      <div className="p-4 flex-auto">
        {/* Chart */}
        <div className="relative h-800-px">
          <canvas id="bar-chart"></canvas>
        </div>
      </div>
    </div>
  );
}

const { url, genericGet, defaultHeaders } = require("./api.utils");

export const LineAPI={
    update: async (id,body) => {
		try {
			console.log({update:'update',id,body});
			const res = await fetch(`${url}lines/update/${id}`, {
				method: "PUT",
				headers: defaultHeaders,
				body: JSON.stringify(body),
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
}
/**
 * Map an array containing images to an object with attributes of Image Uploader Data
 * @param {Array} imagesSource the Array containing images links
 * @param {{id,pictureUrl}} attributeNames the names that the involved attributes have in the source images object
 * @param {string} resultUrlName the name that the attribute containing the url in the Image Uploader has
 * @returns an array of images containing {id?, [resultUrlName]}
 */
export const mapToImageUploaderObject = (
	imagesSource,
	attributeNames = { id: "id", pictureUrl: "pic_url" },
	resultUrlName = "data_url"
) => {
	const arrayOfImages = imagesSource.map((picture) => {
		return {
			id: picture[attributeNames.id],
			[resultUrlName]: picture[attributeNames.pictureUrl],
		};
	});
	return arrayOfImages;
};

import "date-fns";

import MaterialTable from "material-table";
import DateFnsUtils from "@date-io/date-fns";

import { AccessTime, Visibility } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import { useState, useEffect, useMemo, useContext } from "react";
import {
	KeyboardDatePicker,
	KeyboardTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { useDate } from "../../../hooks/useDate";
import MapView from "../../../components/Map/MapView";

import { tableIcons, options } from "../../../utils/tableSettings";
import { consultaDestinoAPI } from "api/consultaDestino.api";
import { MapViewContext } from "contexts/MapViewContext";
import { Marker } from "react-leaflet";
import { customIcon } from "components/Map/MapIcons";
import { useStateNamed } from "hooks/useStateNamed";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { DividedDateTimePicker } from "components/DatePickers/DividedDateTimePicker";


const cols = [
	"id",
	// "origen.coordinates",
	"destino.coordinates",
	"fechaConsulta",
];

const Report = () => {
	let model = useMemo(() => ({ init: new Date(), end: new Date() }), []);
	const [query, setQuery] = useState(false);
	const [views, setViews] = useState([]);
	const state = {
		destinationCoords: useStateNamed([0, 0]),
	};
	const { mapContext } = useContext(MapViewContext);
	const findQueryConsultaDestino = async () => {
		const consultaDestinos = await consultaDestinoAPI.findAll({
			beginDate: model.init.getTime(),
			endDate: model.end.getTime(),
			limit: model["limit"] ?? 10000,
		});
        console.log('findQueryConsultaDestino');
		setViews(consultaDestinos);
		setQuery(false);
	};
	useEffect(() => {
		if (query) {
            console.log('useEffect');
			findQueryConsultaDestino();
		}
	}, [query, model]);
	const handlers = {
		onView: (rowData) => {
			console.log(rowData);
			var latlng = {
				lat: rowData.destino.coordinates[0],
				lng: rowData.destino.coordinates[1],
			};
			console.log({ mapContext });
			mapContext?.val?.map?.flyTo(latlng, 18);
			state.destinationCoords.set(rowData.destino.coordinates);
		},
	};
	return (
		<>
			<div className="flex flex-wrap">
				<div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<div className="relative flex flex-row flex-wrap min-w-0 w-full mb-6 shadow-lg rounded-lg bg-white p-5">
							<h1 className="w-full">Seleccionar Fecha</h1>
							<DividedDateTimePicker init model={model} />
							<DividedDateTimePicker model={model} />

							<TextField
								fullWidth
								type="number"
								variant="outlined"
								label="Cantidad Máxima"
								defaultValue={10000}
								onInput={({ target }) =>
									(model["limit"] = target.value)
								}
							/>

							<button
								type="submit"
								style={{ marginTop: 10 }}
								className="cruzero-bg-light-green-01 text-white active:bg-green-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none"
								onClick={() => {
									if (model.end.getTime() > model.init.getTime()) {
										console.log(model);
										setQuery(true);
									} else {
										alert("RANGO INVÁLIDO");
									}
								}}
							>
								Consultar
							</button>
						</div>
					</MuiPickersUtilsProvider>
				</div>
				<div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
					<MapView destinies={views}>
						<Marker
							icon={customIcon("destination_pin")}
							position={state.destinationCoords.val}
							layerContainer={mapContext?.val?.layerContainer}
						/>
						<MarkerClusterGroup chunkedLoading={true}>
							{views.map((aView) => (
								<Marker
									icon={customIcon("destination_pin")}
									position={aView.destino.coordinates}
									layerContainer={mapContext?.val?.layerContainer}
								/>
							))}
						</MarkerClusterGroup>
					</MapView>
				</div>
				<div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4 mt-10">
					<MaterialTable
						isLoading={query}
						title="Destinos Consultas"
						columns={cols.map((col, index) => ({
							key: index,
							field: col,
							title: col,
						}))}
						data={views}
						options={options}
						icons={tableIcons}
						actions={[
							{
								icon: Visibility,
								tooltip: "Ver Punto",
								onClick: (event, rowData) => {
									// Do save operation
									handlers.onView(rowData);
								},
							},
						]}
					/>
				</div>
			</div>
		</>
	);
};

export default Report;

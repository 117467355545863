import L from "leaflet";

import placeBallonnURL from "../../assets/img/icon_lugares_globo.png";


const icons = {
	'red': require('../../assets/icons/pin1.svg').default,
	'black': require('../../assets/icons/pin2.svg').default,
	'balloon': require('../../assets/img/icon_lugares_globo.png').default,
	'destination_pin':require('../../assets/icons/map/destination_pin.png').default,
}

Object.freeze(icons)
export const PlaceBaloonIcon = new L.Icon({
	iconUrl: placeBallonnURL,
	iconRetinaUrl: placeBallonnURL,
	iconAnchor: null,
	popupAnchor: null,
	shadowUrl: null,
	shadowSize: null,
	shadowAnchor: null,
	iconSize: new L.Point(25, 25),
});

export const customIcon = icon => L.icon({
	iconUrl: icons[icon],
	iconRetinaUrl: icons[icon],
	iconSize: new L.Point(30, 30),
})

export const createIcon=({iconUrl,size=25})=>{
     return new L.Icon({
        
        iconUrl,
        iconRetinaUrl: iconUrl,
        iconAnchor: [12, 41],
        popupAnchor: [12, -41],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(size, size),
        // className: 'leaflet-div-icon'
    });
}

import {
	Button,
	Fab,
	Grid,
	Input,
	MenuItem,
	TextField,
	Typography,
} from "@material-ui/core";
import Map from "components/Map/Map";
import { ModalAlertDialog } from "components/Material/Modals/ModalAlertDialog";
import { getStateNamedStatelesJSON, handleFormChange } from 'utils/formUtils';
import React, { useEffect, useState } from "react";
import { useFetch } from "hooks/useState";
import ImageUploading from "react-images-uploading";
import ModalFormMapCSS from "./ModalFormMap.module.css";
import { useMultiUploader } from "hooks/useMultiUploader";
import {useStateNamed} from "hooks/useStateNamed"
import {
	CloudUpload,
	Delete,
	Edit,
	FavoriteBorderSharp,
} from "@material-ui/icons";
import { PoiAPI } from "api/poi.api";
import Login from "views/auth/Login";
import { CityAPI } from "api/city,api";
import { FileConverterAPI } from "api/file-converter.api";
import { ImageUploader } from "components/Material/Inputs/ImageUploader/ImageUploader";
import { mapToImageUploaderObject } from "components/Material/Inputs/ImageUploader/ImageUploader.utils";
/**
 * fetchType can be create or update
 */
export const DialogFormContainer = ({open, handleClose,reloadTable,  formData,setFormData}) => {
	const [categories, setCategories] = useState([]);
	const [cities, setCities] = useState([]);
	useEffect(() => {
		listPoiTypes();
		listCities();
	}, []);
	

	const listCities = async () => {
		const cities=await CityAPI.getCitiesOfCountry(1)
		
		setCities(cities.data.Cities)
	};
	const listPoiTypes = async () => {
		const res = await PoiAPI.allTypes();
		setCategories(res.data);
	};
	const positionState={
		lat: useStateNamed(formData.lat),
		lon:  useStateNamed(formData.lng),
	}
	const auxFunctions={
		getMarkerInitialPosition:()=>{
		   const position={
			   lat:positionState.lat.val,
			   lng:positionState.lon.val
		   }
		   return position
		},
	setPositionEnd:({lat,lng})=>{
	  
	  positionState.lat.set(lat);
	  positionState.lon.set(lng)
	}}
	const processImagesUrls=async()=>{
		images.set(mapToImageUploaderObject(formData.pictures))	
		
	}
	useEffect(() => {
		positionState.lat.set(formData.lat)
		positionState.lon.set(formData.lng)
		if(formData.pictures!=null){
			processImagesUrls()
		}
	}, [formData]);
	const [form, setForm] = useState(formData);
	// BEGIN OF IMAGE UPLOADEDR
			const images=useStateNamed([]);
			const maxNumber = 69;
			const onChange = (imageList, addUpdateIndex) => {
				console.log('CHANGE',imageList);
				// data for submit
				images.set(imageList);
			};
	//   END OF IMAGE UPLOADER

	const dataManipulationStrategy={
		create:{
			sendData:PoiAPI.create,
			// TODO: in the future this have to be unified in backend and this strategy should dissapear
			mapPictures:(images)=>images.val.map((image)=>image.data_url)
		},
		update:{
			sendData:async(data)=>{
				await PoiAPI.update(data.id,data);
			},
			mapPictures:(images)=>images.val
		}
	}
	/**
	 * Verifies is a string is a url with http protocol
	 * @param {String} stringToVerify 
	 */
	const isStringUrl=(stringToVerify)=>{
		const HTTP_PROTOCOL='http'
		return stringToVerify.substring(0,4).includes(HTTP_PROTOCOL)	
	}
	const fetchType=formData.id===-1?'create':'update'
	const handlers = {
		clickSubmit: async () => {
			const picturesToSend=dataManipulationStrategy[fetchType].mapPictures(images)
			const data = {
				...formData,
				...getStateNamedStatelesJSON(positionState),
				pictures: picturesToSend,
				poi_status_id:3
			};
			console.log(data);
			handleClose();
			await dataManipulationStrategy[fetchType].sendData(data);
			reloadTable();
		},
	};
	return (
		<ModalAlertDialog
			open={open}
			title="Crear Nuevo Punto de Interes"
			closeButton
			okButton
			closeButtonText="Cancelar"
			okButtonText="Aceptar"
			onCloseButtonClick={handleClose}
			onOkButtonClick={handlers.clickSubmit}
		>
			<Grid container spacing={3}>
				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalNombre"
						label="Nombre"
						name="name"
						
						variant="outlined"
						value={formData.name}
						onChange={(e) => handleFormChange(e, formData, setFormData)}
					/>
				</Grid>

				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalwebsite"
						label="Sitio web (url)"
						name="website"
						variant="outlined"
						value={formData.website}
						onChange={(e) => handleFormChange(e, formData, setFormData
							)}
					/>
				</Grid>

				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalDireccion"
						label="Direccion"
						name="direction"
						variant="outlined"
						value={formData.direction}
						onChange={(e) => handleFormChange(e, formData, setFormData)}
					/>
				</Grid>
				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalTelefono"
						label="Telefono"
						name="phone_number"
						type="number"
						variant="outlined"
						value={formData.phone_number}
						onChange={(e) => handleFormChange(e, formData, setFormData)}
					/>
				</Grid>

				<Grid item md={12}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalDescripcion"
						label="Descripcion"
						name="description"
						variant="outlined"
						value={formData.description}
						onChange={(e) => handleFormChange(e, formData, setFormData)}
					/>
				</Grid>
				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="filled-select-currency"
						select
						label="Seleccionar Categoria"
						name="poi_type_id"
						value={formData.poi_type_id}
						onChange={(e) => handleFormChange(e, formData, setFormData)}
						variant="outlined"
					>
						{categories.map((categoryItem) => (
							<MenuItem
								key={categoryItem.id + categoryItem.type_name}
								value={categoryItem.id}
							>
								{categoryItem.type_name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="filled-select-currency"
						select
						label="Seleccionar Ciudad"
						name="city_id"
						value={formData.city_id}
						onChange={(e) => handleFormChange(e, formData, setFormData)}
						variant="outlined"
					>
						{cities.map((cityItem) => (
							<MenuItem
								key={cityItem.id + cityItem.city_name}
								value={cityItem.id}
							>
								{cityItem.city_name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item md={12}>
					<Typography
						style={{
							fontStyle: "",
							fontSize: "1.5em",
							fontFamily: "Montserrat",
						}}
					>
						{" "}
						Seleccione Imagenes:
					</Typography>

					<ImageUploader 
						multiple
						images={images.val}
						onChange={onChange}
						maxNumber={maxNumber}
						dataURLKey="data_url"/>
				</Grid>

				<Grid item md={12}>
					<Typography
						style={{
							fontStyle: "",
							fontSize: "1.5em",
							fontFamily: "Montserrat",
						}}
					>
						{" "}
						Seleccione Ubicacion:
					</Typography>

					<Map dragabbleMarker markerSetPositionEnd={auxFunctions.setPositionEnd} markerInitialPosition={auxFunctions.getMarkerInitialPosition} />
				</Grid>
			</Grid>
		</ModalAlertDialog>
	);
};

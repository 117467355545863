const blobToBase64 =async (blob)=> {
	return new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
}
const convertUrlToBlob = async (anImageUrl) => {
	const response = await fetch(anImageUrl);
	const myBlob = await response.blob();
	return myBlob;
};
export const FileConverterAPI = {
	convertUrlToBlob,

	converFiletUrlToBase64: async (fileUrl) => {
		const blob = await convertUrlToBlob(fileUrl);
		return await blobToBase64(blob);
	},
};

import { useState } from 'react'

export const useDate = (init = true, model) => {
    const [date, setDate] = useState(new Date())

    return {
        date,
        handleDate: date => {
            setDate(date)
            model[init ? 'init' : 'end'] = date
        }
    }
}
import React from "react";
import CardTable from "components/Cards/CardTable";
import { Grid, Button } from "@material-ui/core";

import { Add } from "@material-ui/icons";
import DataTableContainer from "./Containers/DataTable";
import {DialogFormContainer} from "./Containers/DialogFormContainer";

import { url } from "api/api.utils";
import { useModal, useFetch } from "../../../hooks/useState";
import { useModalAlertForm } from "hooks/useModalAlertForm";
import { PoiAPI } from "api/poi.api";
const Tables = () => {
	const { rows, reloadPage: reloadData } = useFetch(PoiAPI.urls.all);
	const defaultData={
		id: -1,
		name: "",
		description: "",
		website: "",
		direction: "",
		phone_number: "",
		pictures:[],
		poi_type_id: "",
		city_id: "",
		lat: -17.7829, lng: -63.181
	}
	const modalProps = useModalAlertForm(defaultData);

	return (
		<>
			<Grid container spacing={1} direction="row">
				<Grid item>
					<Button
						disableElevation
						className="cruzero-bg-light-green-01 text-white active:bg-green-700"
						color="primary"
						variant="contained"
						startIcon={<Add />}
						onClick={()=>{modalProps.setFormData(defaultData)
						 modalProps.handleOpen()}}
					>
						Nuevo Lugar
					</Button>
				</Grid>
				<Grid item xs={12}>
					<DataTableContainer

						modalProps={modalProps}
						rows={rows}
						reloadPage={reloadData}
					/>
				</Grid>
			</Grid>

			<DialogFormContainer
				{...modalProps}
				
				reloadTable={reloadData}
			/>
		</>
	);
};

export default Tables;
// const Tables = () => {
//     return <>
//         <div className="flex flex-wrap mt-4">
//             <div className="w-full mb-12 px-4">
//                 <CardTable />
//             </div>
//             <div className="w-full mb-12 px-4">
//                 <CardTable color="dark" />
//             </div>
//         </div>
//     </>
// }

// export default Tables

import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useStateNamed } from "hooks/useStateNamed";
import {handleFormChangeStateNamed} from 'utils/formUtils'
const contributionStates = ["ACEPTADO", "RECHAZADO", "PENDIENTE"];
/**
 * {
 * initialContributionState: the state (1 of 3) that is currently in the DB for the table
 * onChangeStateCallback: it is a function to called after the state change on the select it has as 1st parameter the object containing the stateNamed, and 2nd param the value changed (this because state changing is not fast)
 * }
 * @param {*} param0 
 * @returns 
 */
export const StateSelect = ({initialContributionState,onChangeStateCallback}) => {
	const state = {
		selectedState: useStateNamed(initialContributionState),
	};
   
	return (
		<Select
			labelId="demo-simple-select-label"
			id="demo-simple-select"
			value={state.selectedState.val}
			label="Estado de Contribucion"
			name="selectedState"
			//
			onChange={(e) => handleFormChangeStateNamed(e, state,onChangeStateCallback)}
		>
			{contributionStates.map((contributionState) => (
				<MenuItem value={contributionState}>{contributionState}</MenuItem>
			))}
		</Select>
	);
};

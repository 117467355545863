import { url } from "./api.utils";

export const gtfsAPI = {
	sendFile: async (body) => {
		try {
            console.log({body});
			const res = await fetch(`${url}bulk2/create?city_id=12&type=1`, {
				method: "POST",
				body: body,
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	
};

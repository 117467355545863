import { genericGet } from "./api.utils";

export const LogOfTransportAPI={
    findAll: async ({beginDate,endDate,limit=1000}) => {
		try {
			const res = await genericGet(`logsOfConsultedTransport`, {
				beginDate,endDate,limit
			});
			return res;
			
		} catch (error) {
			console.log({error});
		}
	},getCount: async ({ isGroupByTransport = true ,beginDate,endDate,limit=1000}) => {
		try {
			const res = await genericGet(`logsOfConsultedTransport/count`, {beginDate,endDate,limit,
				isGroupByTransport
			});
			return res;
		} catch (error) {
			console.log({error});
		}
	},
}
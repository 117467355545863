import { genericGet, defaultHeaders, url, genericDestroy } from "./api.utils";

export const GTFSFileAPI = {
	findAll: async () => {
		try {
			const res = await genericGet("gtfs/files");
			return res;
		} catch (error) {
			console.log(error);
		}
	},
	createFile: async (form) => {
		try {
			const res = await fetch(`${url}gtfs/files`, {
				method: "POST",

				body: form,
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	destroy: async (id) => {
		try {
			return await genericDestroy("/gtfs/files", id);
		} catch (error) {
			console.log(error);
		}
	},
	overwrite: async (id, form) => {
		try {
			console.log(id);
			const res = await fetch(`${url}gtfs/restartOTP/${id}`, {
				method: "PUT",
				body: form,
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	/**
	 * Generates a download in the browser
	 * @param {*} id the gtfs file id
	 * @returns 
	 */
	download: async (id) => {
		try {
			
			
			const data = await fetch(`${url}gtfs/files/download/${id}`)
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	restartOTP: async (id, form) => {
		try {
			const res = await genericGet("gtfs/restartOTP");
			console.log(res);
			return res;
		} catch (error) {
			console.log(error);
		}
	},
	validation: async ({ filename }) => {
		try {
			return await genericGet("/gtfs/files/validate", { filename });
		} catch (error) {
			console.log(error);
		}
	},
};

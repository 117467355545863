import { Button, Card, Container, Fab, Grid } from "@material-ui/core";
import { CloudUpload, Delete, Edit } from "@material-ui/icons";
import React from "react";
import { useEffect } from "react";
import ReactImageUploading from "react-images-uploading";
import ImageUploaderCSS from "./ImageUploader.module.css";
export const ImageUploader = ({
	multiple = true,
	images,
	onChange,
	maxNumber,
	dataURLKey = "data_url",
}) => {
	useEffect(() => {
		console.log({ images });
	}, [images]);
    
	return (
		<ReactImageUploading
			multiple={multiple}
			value={images}
			onChange={onChange}
			maxNumber={maxNumber}
			dataURLKey={dataURLKey}
		>
			{({
				imageList,
				onImageUpload,
				onImageRemoveAll,
				onImageUpdate,
				onImageRemove,
				isDragging,
				dragProps,
			}) => (
				// write your building UI
				< >
					<Grid container spacing={2}>
						<Grid item sm={12}>
							<Button
								variant="contained"
								color="default"
								startIcon={<CloudUpload />}
								style={isDragging ? { backgroundColor: "red" } : null}
								onClick={onImageUpload}
								{...dragProps}
							>
								Subir
							</Button>
						</Grid>
						<Grid item>
							<Grid container spacing={1}>
								{imageList.map((image, index) => (
									<Grid item  key={index}>
										<Card elevation={6} >
											<Grid
												container
												justify="space-around"
												alignItems="center"
												spacing={1}
												style={{
													backgroundColor: "#ffffff",
													borderRadius: "15px",
												}}
												direction="column"
											>
												<div > 
                                                <img
													src={image.data_url}
													alt=""
													style={{
                                                        width:'10rem',
                                                        height:'10rem',
														objectFit: "cover",
													}}
												/>
                                                </div>

												<Grid item>
													<Fab
														size="small"
														color="primary"
														onClick={() => onImageUpdate(index)}
													>
														<Edit />
													</Fab>
													<Fab
														size="small"
														color="secondary"
														onClick={() => onImageRemove(index)}
													>
														<Delete />
													</Fab>
												</Grid>
											</Grid>
										</Card>
									</Grid>
								))}{" "}
							</Grid>
						</Grid>
					</Grid>

					{/* <Button onClick={onImageRemoveAll} variant="contained">
                Borrar Imagenes
            </Button> */}
				</>
			)}
		</ReactImageUploading>
	);
};

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react'


import DataTableCustom from 'components/Material/DataTables/DataTableCustom'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { EditControl } from 'react-leaflet-draw';
import { TileLayer, MapContainer, FeatureGroup, Polyline } from 'react-leaflet';
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import L, { Layer } from 'leaflet';
import { saveAs } from 'file-saver';
import GTFSUploader from 'components/GTFS/GTFSUploader';

const cols = [
    'id',
    'transport_name',
    'images',
    'time'
]




const DataTableContainer = ({ rows, reloadPage, modalProps, setSelectedContribution }) => {

    const onView = (rowData) => {
        modalProps.handleOpen();
        setSelectedContribution(rowData?.Lines?.[0]?.Shape)
        //console.log(rowData?.Lines?.[0]?.Shape)
    }


    const [title, setTitle] = useState('')


    const [map, setMap] = useState(null);

    const [mapContribution, setMapContribution] = useState([]);

    return (
        <>

            <DataTableCustom
                MaterialTableProps={{ title: "Contribuciones", key: 'MaterialLugar' }}
                cols={cols}
                rowsData={rows}
                actionColumn
                actionColumnFunctions={{ onView }}
                // TODO: deleted this propertie for the component this should be remade
                filterResultHandler={setMapContribution}
            />

            <Accordion>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6" style={{ paddingLeft: '24px' }}>Editor <i className="fas fa-draw-polygon"></i></Typography>
                </AccordionSummary>

                <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-lightBlue-500 mb-3">
                    <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                        <div className="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
                            <ul className="flex flex-col lg:flex-row list-none ml-auto">
                                <li className="nav-item">
                                    <a className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" style={{ cursor: 'pointer' }} onClick={() => {

                                        var drawnItems = new L.FeatureGroup();
                                        map.eachLayer(function (layer) {

                                            if (layer instanceof L.Polyline) {

                                                drawnItems.addLayer(layer);
                                            }

                                        });
                                        var data = drawnItems.toGeoJSON();
                                        var blob = new Blob([JSON.stringify(data)], { type: "text/plain" });
                                        saveAs(blob, `${(title) ? title : 'default'}.geojson`);

                                        //console.log(map.getBounds());

                                    }}>
                                        <i className="fab fas fa-file-export text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Exportar</span>
                                    </a>
                                </li>
                                <input onChange={event => setTitle(event.target.value)} type="text" placeholder="Ruta.geojson" className="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full" />
                            </ul>
                        </div>
                    </div>
                </nav>






                <AccordionDetails>
                    <MapContainer
                        style={{ width: '100%', height: '640px' }}
                        center={[51.505, -0.09]}
                        zoom={13}
                        scrollWheelZoom={true}
                        whenCreated={setMap}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <FeatureGroup>
                            <EditControl
                                position='topright'
                                draw={{
                                    rectangle: false,
                                    polygon: false,
                                    circle: false,
                                    circlemarker: false,
                                    marker: false
                                }}
                            />

                        </FeatureGroup>
                        {
                            mapContribution.map(item => {
                                return <Polyline></Polyline>
                            })
                        }
                    </MapContainer>

                </AccordionDetails>

            </Accordion>
            
            <GTFSUploader></GTFSUploader>
        </>
    );
}

export default DataTableContainer
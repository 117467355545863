import { genericGet } from "./api.utils";

export const zoneAPI={
    getCount:async()=>{
		try {
			const res = await genericGet(`zones/count`);
			return res;
			
		} catch (error) {
			console.log(error);
		}
		
	}
}
const { MapViewContext } = require("contexts/MapViewContext");
const { useEffect, useState, useContext } = require("react");

export const useMapViewExporter = (context) => {
	const { mapContext } = useContext(MapViewContext);

	useEffect(() => {
		mapContext.set(context);
	}, []);
};
export const useMapViewContext = () => {
	const { mapContext } = useContext(MapViewContext);

	return {mapContext}
};

import React from "react";
import driverIconURL from 'assets/icons/cardStats/drivers_icon.png'
import earningsIconURL from 'assets/icons/cardStats/earnings_icon.png'
import trafficIconURL from 'assets/icons/cardStats/traffic_icon.png'
import usersIconURL from 'assets/icons/cardStats/users_icon.png'
// components
import CardStats from "components/Cards/CardStats.js";

export default function HeaderStats() {
  return <>
    {/* Header */}
    <div className="cruzero-bg-dark-green-01 relative  md:pt-32 pb-32 pt-12">
      <div className="px-4 md:px-10 mx-auto w-full">
        <div>
          {/* Card stats */}
          <div className="flex flex-wrap">
            
            <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
              <CardStats
                statSubtitle="USUARIOS"
                statTitle="2,356"
                statArrow="down"
                statPercent="3.48"
                statPercentColor="text-red-500"
                statDescripiron="Desde el ultimo mes "
                statIconName="fas fa-users"
                statIconUrl={usersIconURL}
                statIconColor="cruzero-bg-dark-green-01"
              />
            </div>
            <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
              <CardStats
                statSubtitle="CONDUCTORES"
                statTitle="924"
                statArrow="down"
                statPercent="1.10"
                statPercentColor="text-orange-500"
                statDescripiron="Desde el ultimo mes "
                statIconName="fas fa-address-card"
                statIconUrl={driverIconURL}
                statIconColor="cruzero-bg-dark-green-01"
              />
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </>
}

import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle, Grid, Typography
} from "@material-ui/core";
import { contributionAPI } from "api/contribution.api";
import { PoiAPI } from "api/poi.api";
import Map from "components/Map/Map";
import { ModalAlertDialog } from "components/Material/Modals/ModalAlertDialog";
import React, { useState } from "react";
import { defaultHeaders } from "../../../../api/api.utils";

const ModalForm = ({ selectedContribution, open, handleClose, reloadTable }) => {
	const [categories, setCategories] = useState([]);

	const [openModal, setOpenModal] = React.useState(false);
	const handleClickOpenCheck = () => {
		setOpenModal(true);
	  };
	
	  const handleCloseCheck = () => {
		setOpenModal(false);
	  };


	const listPoiTypes = async () => {
		const res = await PoiAPI.allTypes();
		setCategories(res.data);
	};

	const [position, setPosition] = useState({ lat: 0, lng: 0 });
	const [form, setForm] = useState({
		id: -1,
		name: "",
		description: "",
		website: "",
		direction: "",
		phone_number: "",
		poi_type_id: "",
	});
	// BEGIN OF IMAGE UPLOADEDR
	const [images, setImages] = useState([]);

	//   END OF IMAGE UPLOADER


	const [mapData, setMapData] = useState({});

	const handlers = {
		clickSubmit: async () => {
			const fileName = "contribucion" + selectedContribution?.id;
			const json = JSON.stringify(selectedContribution);
			const blob = new Blob([json], { type: 'application/json' });
			const href = await URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = href;
			link.download = fileName + ".json";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	};
	return (
		<ModalAlertDialog
			open={open}
			title="Ver Contribucion"
			closeButton
			okButton
			saveButton
			closeButtonText="Cancelar"
			okButtonText="Descargar archivo"
			saveButtonText="Guardar cambios"
			onCloseButtonClick={handleClose}
			onOkButtonClick={handlers.clickSubmit}
			onSaveButtonClick={async () => {
				handleClickOpenCheck()
				/*
				console.log(mapData);
				try {
					const res = await fetch(contributionAPI.urls.update_shape, {
						method: 'POST',
						headers: defaultHeaders,
						body: JSON.stringify(mapData),
					});
					const data = await res.json();
				} catch (error) {
					console.log(error);
				}*/
			}}
		>
			<Grid container spacing={3}>

				<Grid item md={6}>

				</Grid>


				<Grid item md={12}>
					<Typography
						style={{
							fontStyle: "",
							fontSize: "1.5em",
							fontFamily: "Montserrat",
						}}
					>
						{" "}
						Ubicacion de Contribucion :
					</Typography>

					<Map markerSetPositionEnd={setPosition} contributionGeoJSON={true} contributionGeoJSONData={selectedContribution} isEditable={true} editableAction={setMapData} />
				</Grid>
			</Grid>
			<Dialog
				open={openModal}
				keepMounted
				onClose={handleCloseCheck}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{"¿Quieres actualizar esta ruta?"}</DialogTitle>
       
				<DialogActions>
					<Button onClick={async ()=>{
						console.log(mapData);
						try {
							const res = await fetch(contributionAPI.urls.update_shape, {
								method: 'POST',
								headers: defaultHeaders,
								body: JSON.stringify(mapData),
							});
							const data = await res.json();
							handleCloseCheck();
							handleClose();
						} catch (error) {
							console.log(error);
						}
					}} color="primary">
						Aceptar
					</Button>
					<Button onClick={handleCloseCheck} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		</ModalAlertDialog>
	);
};
export default ModalForm;

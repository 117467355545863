import { genericGet } from "./api.utils";

export const CityAPI = {
	findAll: async (type_id) => {
		try {
			const res = await genericGet(`cities`);

			return res;
		} catch (error) {
			console.log(error);
		}
	},
	getCitiesOfCountry: async (country_id) => {
		try {
			const res = await genericGet(`country/cities`, {
				country_id,
			});
			return res;
		} catch (error) {
			console.log(error);
		}
	},
};

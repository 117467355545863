import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const isSelected = (path) => {
	return window.location.href.indexOf(`${path}`) !== -1
		? ["text-green-500 hover:text-green-400", "text-green-400"]
		: ["text-blueGray-700 hover:text-blueGray-500", "opacity-75"];
};
export const SidebarDropdown = ({
	key,
	className,
	color,
	title,
	icon,
	iconUrl,
	baseRoute,
	subMenuArray,
}) => {
	const [isDropdownShow, setDropdownPopoverShow] = useState(false);

	icon = ""; //to erase the icon of font awesome
	return (
		<>
			<li
				key={key}
				className={`pointer-hand items-center text-xs uppercase py-3 font-bold block ${className}`}
				onClick={(e) => {
					e.preventDefault();
					setDropdownPopoverShow(!isDropdownShow);
				}}
			>
				<img
					style={{ width: "2em", height: "100%", display: "inline" }}
					src={`${iconUrl}`}
					alt=""
				/>{" "}
				<i className={`${icon}   mr-2 text-sm ${color}`}>{`${title}`}</i>
				<i className="fas fa-chevron-down"></i>
			</li>
			<div
				className={
					(isDropdownShow ? "block " : "hidden ") +
					"bg-white  text-base z-50 float-left list-none text-left rounded min-w-48"
				}
			>
				{subMenuArray.map(([route, icon, iconUrl], index) => {
					icon = ""; //to erase the icon of font awesome
					let routeSelected = isSelected(`${baseRoute}/${route}`);
					return (
						<Link
							key={index}
							to={`${baseRoute}/${route}`}
							className={`text-xs uppercase py-2 font-bold block transition duration-500 ease-in-out ${routeSelected}`}
						>
							<img
								style={{
									width: "2em",
									height: "100%",
									display: "inline",
								}}
								src={`${iconUrl}`}
								alt=""
							/>
							<i className={`${icon} mr-2 text-sm ${color}`}>
								{` ${route}`}
							</i>
						</Link>
					);
				})}
			</div>
		</>
	);
};

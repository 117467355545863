import Swal from "sweetalert2";

export const url = `${process.env.REACT_APP_API}`;

export const defaultHeaders = {
	Accept: "Application/json",
	"Content-Type": "Application/json",
	// 'x-access-token': localStorage.getItem('token')
};

/**
 * A generic API for destroying
 * NOTE:the url must have the id at the end
 * @param {*} urlToDelete No "/" at the end
 * @param {*} id
 * @returns
 */
export const genericDestroy = async (urlToDelete, id) => {
	try {
		const res = await fetch(`${url}${urlToDelete}/${id}`, {
			method: "DELETE",
			crossDomain: true,
			headers: defaultHeaders,
		});
		const data = await res.json();
		return data;
	} catch (error) {
		console.log(error);
	}
};

/**
 * A generic API for GET, it contains the default host
 * @param {*} urlToGet No "/" at the end or beginning
 * @param {JSON} query it should be a json
 * @returns
 */
export const genericGet = async (urlToGet, query, useMainUrl = true) => {
	Swal.fire("Cargando");
	Swal.showLoading();
	let mainURL = new URL(`${url}${urlToGet}`);

	var params = query;
	mainURL.search = new URLSearchParams(params).toString();
	try {
		const res = await fetch(`${mainURL}`, {
			method: "GET",
			crossDomain: true,
			headers: defaultHeaders,
		});
		const data = await res.json();
        Swal.close()
		return data;
        
	} catch (error) {
		console.log(error);
	}
};

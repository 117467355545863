import { genericGet } from "./api.utils";

export const LogOfPoiAPI={
    findAll: async ({beginDate,endDate,limit=1000}) => {
		try {
			const res = await genericGet(`logsOfConsultedPoi`, {
				beginDate,endDate,limit
			});
			return res;
			
		} catch (error) {
			console.log({error});
		}
	},getCount: async ({ isGroupByPoi = true ,beginDate,endDate,limit=1000}) => {
		try {
			const res = await genericGet(`logsOfConsultedPoi/count`, {beginDate,endDate,limit,
				isGroupByPoi
			});
			return res;
		} catch (error) {
			console.log({error});
		}
	},
}
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, ButtonGroup } from "@material-ui/core";
import { Delete, Edit, Timer, Visibility } from "@material-ui/icons";
import { ToggleButton } from "@material-ui/lab";
import { url } from 'api/api.utils';
import 'leaflet/dist/leaflet.css';
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import { useRef, useState } from "react";
import { options, tableIcons } from "../../../utils/tableSettings";




/**
 *titlesReplace should be a json like {myOldUglyTitle:'NewTitle'}
 * actionColumnFunctions example: actionColumnFunctions={ onView:(rowData)=>{},onEdit:(rowData)=>{},onDelete:(rowData)=>{} }
 * customColumnRenders: it is a array of  functions that will render a column, you can ask for conditions in it
 *  example:
 * stateContribution:({colName,obj})=>{
            if(colName=="poi_contributionstatus"){
                obj.render=(data)=>{
                    return data[colName]+'COLA';
                }
            }
        }

		
 * @returns
 */
const DataTableCustom = ({
	MaterialTableProps,
	cols,
	rowsData,
	actionColumn,
	actionColumnFunctions,
	titlesReplace,
	filterResultHandler,
	customColumnRenders=[],
}) => {
	const extraCols = {};
	const customRowFunctions = {
		actionColumnFunctions,
	};
	if (actionColumn) {
		extraCols.actions = true;
	}


	  const tableRef = useRef();

	return (
		<>
			<MaterialTable
				{...MaterialTableProps}
				style={{ paddingLeft: 10, paddingRight: 10 }}
				columns={columns({ cols, customRowFunctions, extraCols, titlesReplace,customColumnRenders })}
				data={rowsData}
				options={options}
				icons={tableIcons}
				onSearchChange={()=>{filterResultHandler(tableRef.current.dataManager.searchedData)}}
				tableRef={tableRef}
				components={{
					Toolbar: props => (
						<div>
							<MTableToolbar {...props}   />
							<div style={{ padding: '0px 24px 8px' }}>
							
							</div>
						</div>
					),
				}}
			/>

			{/* <ModalForm
				open={open}
				model={model}
				closeModal={closeModal}
				reloadPage={reloadData}
			/> */}
			
		</>
	);
};





const columns = ({ cols, customRowFunctions, extraCols, titlesReplace,customColumnRenders }) => {
	const { actionColumnFunctions } = customRowFunctions;
	let tmp = cols.map((col, index) => {
		let obj = {
			// key: index + col,
			title: col,
			field: col,
		};
		if (titlesReplace ? titlesReplace[col] : false) {
			obj.title = titlesReplace[col];
		}
		if (col === "fechaInicio" || col == 'fechaFin') {
			obj.align = "center";
			obj.searchable = false;
			obj.render = (data) => (
				moment(data[col]).format('DD/MM/YYYY')
			);
		}
		//The custom condition functions
		customColumnRenders.map((aFunction)=>{
			aFunction({colName:col,obj})
		})



		return obj;
	});
	if (extraCols.actions) {
		tmp.push({
			//key:'action',
			title: "Acción",
			align: "center",
			render: (row) => (
				//the buttons ares showed if their function is not null
				<ButtonGroup variant="outlined" size="large">
					{actionColumnFunctions?.onSuspend ?
						<Button
							key={"SuspendeBtn" + row.id}
							title="Suspender"
							style={{ color: "blue" }}
							onClick={() => actionColumnFunctions.onSuspend(row)}
							children={[
								<Timer key={"Suspend" + row.id} fontSize="small" />,
							]}
						/> : 'null'}
					{actionColumnFunctions?.onView ? <Button
						key={"detailBtn" + row.id}
						title="Ver Detalle"
						style={{ color: "#1876D2" }}
						onClick={() => actionColumnFunctions.onView(row)}
						children={[
							<Visibility
								key={"visibility" + row.id}
								fontSize="small"
							/>,
						]}
					/> : null}
					{actionColumnFunctions?.onEdit ?
						<Button
							key={"editBtn" + row.id}
							title="Editar"
							color="primary"
							onClick={() => actionColumnFunctions.onEdit(row)}
							children={[<Edit key={"Edit" + row.id} fontSize="small" />]}
						/> : null}
					{actionColumnFunctions?.onDelete ?
						<Button
							key={"deleteBtn" + row.id}
							title="Eliminar"
							style={{ color: "#F44336" }}
							onClick={() => actionColumnFunctions.onDelete(row)}
							children={[
								<Delete key={"Delete" + row.id} fontSize="small" />,
							]}
						/> : null}

				</ButtonGroup>
			),
		});
	}
	return tmp;
};

export default DataTableCustom;

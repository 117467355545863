import './matrix.css'

import { Parser } from 'json2csv'
import { CSVLink } from 'react-csv'
import { useState, useEffect } from 'react'

import {
    CircularProgress, TextField, MenuItem
} from '@material-ui/core'
import { consultaDestinoAPI } from 'api/consultaDestino.api'

const Matrix = ({
    col = 1, row = 1, limit = 10, model, count, setCol, setRow, setLimit, reload, setReload, customOriginDestinationZones=[]
}) => {
    const [csv, setCsv] = useState('')
    const [data, setData] = useState([])

    const zones = !hasCustomDestinationZones()?
    [...Array(limit + 1).keys()]
    :[0,...customOriginDestinationZones]
    const pages = [...Array(Math.round(count / limit + .4)).keys()]

    const matrix = Array(limit).fill(Array(limit).fill(0))
    const { init, end } = { init: model.init.getTime() / 1000, end: model.end.getTime() / 1000 }
    const getAllConsultaDestinoFromDates=async()=>{
        const consultas=await consultaDestinoAPI.findByUVZone({beginDate:init,endDate:end,col,row,limit});
        setData(consultas);
        setReload(false)
    }
    useEffect(() => {
        if (reload) {
            getAllConsultaDestinoFromDates()
           
        }
    }, [row, col, end, init, matrix, limit, reload, setReload, data])
    const MatrixTable=({ arrayToMap, yAddIndexFormula,xAddIndexFormula})=>{
        console.log({arrayToMap});
        return arrayToMap.map(i => {
            let res;
            let y =   i + yAddIndexFormula 
            if (i === 0) {
                res = arrayToMap.map(j => {
                    return <td className="cell font-bold">{j === 0 ? 'O/D' : j +xAddIndexFormula }</td>
                })
            } else {
                res = arrayToMap.map(j => {
                    let x = j +xAddIndexFormula 
                    if (j === 0) {
                        return <td className="cell font-bold">{y}</td>
                    }
                    if (x <= count && y <= count) {
                        let index = data.findIndex(({ origenId, destinoId }) => {
                            return x === destinoId && y === origenId
                        })
                       
                        return <td className="cell font-bold">{index < 0 ? 0 : data[index]['count']}</td>
                    }
                    return <td className="cell font-bold">X</td>
                })
            }
            return <tr>{res}</tr>;
        })
    }

    return <>
        <div className="w-full flex-1 flex-row">
            <CSVLink
                id='csv'
                data={csv}
                filename={`consultaDestino-R${row}-C${col}-L${limit}-${new Date()}.csv`}
                onClick={() => {
                    const body = [], headers = []
                    const table = document.getElementById('table')

                    table.childNodes.forEach((tr, i) => {
                        let json = {}

                        tr.childNodes.forEach((td, j) => {
                            if (i === 0) {
                                headers.push(td.textContent);
                            } else {
                                json[headers[j]] = td.textContent
                            }
                        })

                        if (Object.keys(json).length) {
                            body.push(json)
                        }
                    })

                    const parser = new Parser({ fields: headers })
                    setCsv(parser.parse(body))
                }}
            > Descargar CSV</CSVLink>
            <br />
            <TextField
                select
                label="Row"
                value={row}
                onChange={({ target }) => {
                    setRow(target.value)
                    if (init < end) {
                        setReload(true)
                    }
                }}
            >
                {pages.map(page => <MenuItem key={page} value={page + 1}>{page + 1}</MenuItem>)}
            </TextField>
            <TextField
                select
                label="Col"
                value={col}
                style={{ marginLeft: 15, marginRight: 15 }}
                onChange={({ target }) => {
                    setCol(target.value)
                    if (init < end) {
                        setReload(true)
                    }
                }}
            >
                {pages.map(page => {
                    return <MenuItem key={page} value={page + 1}>{page + 1}</MenuItem>
                })}
            </TextField>
            <TextField
                select
                label="Limit"
                value={limit}
                onChange={({ target }) => {
                    setLimit(target.value)
                    if (init < end && target.value > limit) {
                        setReload(true)
                    }
                }}
            >
                {[10, 20, 30, 50].map(val => {
                    return <MenuItem key={val} value={val}>{val}</MenuItem>
                })}
            </TextField>
        </div>
        <br />
        {reload ?
            <div className="w-full"> <CircularProgress /></div>
            : <table className="w-full" id="table">
                {<MatrixTable arrayToMap={zones}
                 xAddIndexFormula={hasCustomDestinationZones()?0:limit * (col - 1)}
                 yAddIndexFormula={hasCustomDestinationZones()?0:limit * (row - 1) }/>
                 }
            </table>
        }
    </>

    function hasCustomDestinationZones() {
        return customOriginDestinationZones.length != 0
    }
}

export default Matrix
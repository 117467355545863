import { GlobalDialogContext } from "contexts/GlobalDialogContext";
import { useContext, useEffect } from "react"

export const useGlobalDialog=()=>{
    const {children,open} = useContext(GlobalDialogContext)
    const show =()=>{
        open.set(true);
        console.log({open});
    }
    const isOpen=open.val
    /**
     * Shows the dialog with the param widget inside
     * @param {*} widget 
     */
    const showWidget=(widget)=>{
        children.set(widget);
        show();
    }
    const hide=()=>{
        open.set(false);
    }
     
    return {showWidget,isOpen,show,hide,children}
}
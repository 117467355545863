const { url, genericGet, defaultHeaders } = require("./api.utils");

export const consultaDestinoAPI = {
	getTodayVisits: async ({ isGroupPerHour = true }) => {
		try {
			const clientTimeZone=Intl.DateTimeFormat().resolvedOptions().timeZone;
			const res = await genericGet(`consultaDestinos/today`, {
				isGroupPerHour,timeZone:clientTimeZone
			});
			return res;
		} catch (error) {
			console.log({error});
		}
	},
	findAll: async ({beginDate,endDate,limit=1000}) => {
		try {
			const res = await genericGet(`consultaDestinos`, {
				beginDate,endDate,limit
			});
			return res;
			
		} catch (error) {
			console.log({error});
		}
	},
	findByUVZone: async ({beginDate,endDate,col,row,limit=1000}) => {
		try {
			const res = await genericGet(`consultaDestinos/zonas`, {
				beginDate,endDate,col,row,limit
			});
			return res;
			
		} catch (error) {
			console.log({error});
		}
	},

};

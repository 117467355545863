import {
	Button,
	Grid,
	MenuItem,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useFetch } from "hooks/useState";
import React, { useEffect, useState } from "react";
import { handleFormChange } from "utils/formUtils";
import DataTableContainer from "./Containers/DataTable";
import { TransportTypeAPI } from "api/transportType.api";
import { useStateNamed } from "hooks/useStateNamed";
import { TransportAPI } from "api/transport.api";
import ModalFormMap from "./Containers/ModalFormMap";
import { useModalAlertDialog } from "hooks/useModalAlertDialog";

function SelectTransport(props) {
	return (
		<TextField
			style={{
				width: "100%",
			}}
			id="filled-select-currency"
			select
			name="selectedTransportTypeId"
			value={props.selectedTransportTypeId}
			onChange={(e) => props.onSelectChange(e)}
			variant="outlined"
		>
			{props.items?.data?.map((aTransportType) => (
				<MenuItem
					key={aTransportType.id + aTransportType.transport_type_name}
					value={aTransportType.id}
				>
					{aTransportType.transport_type_name}
				</MenuItem>
			))}
		</TextField>
	);
}

const Transports = () => {
	const { rows: transportTypesData = [{ id: -1, transport_type_name: "Seleccione uno" }] } =
		useFetch(TransportTypeAPI.urls.all);

	const [form, setForm] = useState({
		selectedTransportTypeId: "",
		selectedTransportData: {},
	});

	const transportsData = useStateNamed([]);
	const auxFunctions = {
		listTransports: async () => {
			const transportRes = await TransportAPI.listAllOfType(
				form.selectedTransportTypeId
			);
			console.log(transportRes?.data);
			transportsData.set(transportRes?.data);
		},
	};
	//OnSelectedTransportTypeId Change.
	useEffect(() => {
		if (form.selectedTransportTypeId != "") {
			auxFunctions.listTransports();
		}
	}, [form.selectedTransportTypeId]);

	const handlers = {
		selectChange: (e) => {
			handleFormChange(e, form, setForm);
		},
	};
	return (
		<>
			<div className="relative flex flex-row flex-wrap min-w-0 w-full mb-6 shadow-lg rounded-lg bg-white p-5">
				<Grid container>
					<Grid item md={6}>
						<h4>Seleccione tipo de transporte</h4>
						<SelectTransport
							items={transportTypesData}
							selectedTransportTypeId={form.selectedTransportTypeId}
							onSelectChange={handlers.selectChange}
						></SelectTransport>
						<Grid container> </Grid>
						<Paper elevation={3}></Paper>
					</Grid>
					<Grid item sm={12}>
						<DataTableContainer
							reloadPage={auxFunctions.listTransports}
							rows={transportsData.val}
							title="Transportes"
						></DataTableContainer>
					</Grid>
				</Grid>
				
			</div>
		</>
	);
};

export default Transports;

import {
	Button,
	Fab,
	Grid,
	Input,
	MenuItem,
	TextField,
	Typography,
} from "@material-ui/core";
import Map from "components/Map/Map";
import { ModalAlertDialog } from "components/Material/Modals/ModalAlertDialog";
import { handleFormChange } from "../../../../utils/formUtils";
import React, { useEffect, useState } from "react";
import { useFetch } from "hooks/useState";
import ImageUploading from "react-images-uploading";
import { useMultiUploader } from "hooks/useMultiUploader";
import {
	CloudUpload,
	Delete,
	Edit,
	FavoriteBorderSharp,
} from "@material-ui/icons";
import { PoiAPI } from "api/poi.api";
const ModalForm = ({open, handleClose }) => {
	const [categories, setCategories] = useState([]);
	useEffect(() => {
		listPoiTypes();
	}, []);

	const listPoiTypes = async () => {
		const res = await PoiAPI.allTypes();
		setCategories(res.data);
	};
	
	const [position, setPosition] = useState({ lat: 0, lng: 0 });
	const [form, setForm] = useState({
		id:-1,
		name: "",
		description: "",
		website: "",
		direction: "",
		phone_number: "",
		poi_type_id: "",
	});
	// BEGIN OF IMAGE UPLOADEDR
	const [images, setImages] = useState([]);
	const maxNumber = 69;
	const onChange = (imageList, addUpdateIndex) => {
		// data for submit
		console.log(imageList, addUpdateIndex);
		setImages(imageList);
	};
	//   END OF IMAGE UPLOADER

	const handlers = {
		clickSubmit: async () => {
			const pictures=images.map((image)=>image.data_url)
			const data = {
				...form,
				lat: position.lat,
				lon: position.lng,
				pictures,
				poi_status_id:3
			};
			console.log(data);
			handleClose();
			await PoiAPI.create(data);
		},
	};
	return (
		<ModalAlertDialog
			open={open}
			title="Crear Nuevo Punto de Interes"
			closeButton
			okButton
			closeButtonText="Cancelar"
			okButtonText="Aceptar"
			onCloseButtonClick={handleClose}
			onOkButtonClick={handlers.clickSubmit}
		>
			<Grid container spacing={3}>
				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalNombre"
						label="Nombre"
						name="name"
						
						variant="outlined"
						value={form.name}
						onChange={(e) => handleFormChange(e, form, setForm)}
					/>
				</Grid>

				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalwebsite"
						label="Sitio web (url)"
						name="website"
						variant="outlined"
						value={form.website}
						onChange={(e) => handleFormChange(e, form, setForm)}
					/>
				</Grid>

				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalDireccion"
						label="Direccion"
						name="direction"
						variant="outlined"
						value={form.direction}
						onChange={(e) => handleFormChange(e, form, setForm)}
					/>
				</Grid>
				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalTelefono"
						label="Telefono"
						name="phone_number"
						type="number"
						variant="outlined"
						value={form.phone_number}
						onChange={(e) => handleFormChange(e, form, setForm)}
					/>
				</Grid>

				<Grid item md={12}>
					<TextField
						style={{ width: "100%" }}
						id="TextModalDescripcion"
						label="Descripcion"
						name="description"
						variant="outlined"
						value={form.description}
						onChange={(e) => handleFormChange(e, form, setForm)}
					/>
				</Grid>
				<Grid item md={6}>
					<TextField
						style={{ width: "100%" }}
						id="filled-select-currency"
						select
						label="Seleccionar Categoria"
						name="poi_type_id"
						value={form.poi_type_id}
						onChange={(e) => handleFormChange(e, form, setForm)}
						variant="outlined"
					>
						{categories.map((categoryItem) => (
							<MenuItem
								key={categoryItem.id + categoryItem.type_name}
								value={categoryItem.id}
							>
								{categoryItem.type_name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item md={12}>
					<Typography
						style={{
							fontStyle: "",
							fontSize: "1.5em",
							fontFamily: "Montserrat",
						}}
					>
						{" "}
						Seleccione Imagenes:
					</Typography>

					
				</Grid>

				<Grid item md={12}>
					<Typography
						style={{
							fontStyle: "",
							fontSize: "1.5em",
							fontFamily: "Montserrat",
						}}
					>
						{" "}
						Seleccione Ubicacion:
					</Typography>

					<Map dragabbleMarker markerSetPositionEnd={setPosition} />
				</Grid>
			</Grid>
		</ModalAlertDialog>
	);
};
export default ModalForm;

import { useFetchFunction } from "hooks/useFetchFunction";
import MaterialTable from "material-table";
import React from "react";
import { tableIcons, options } from "utils/tableSettings";
import { GTFSFileAPI } from "api/gtfsFile.api";
import { useStateNamed } from "hooks/useStateNamed";
import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import { Delete, CloudDownload } from "@material-ui/icons";
import Swal from "sweetalert2";
import { CityAPI } from "api/city,api";
import { handleFormChangeStateNamed } from "utils/formUtils";
import { generateDownload } from "utils/generateDownload";
// import GTFSFileAPI from 'src/api/'
const cols = [
	{ key: 1, field: "id", title: "Identificador" },
	{ key: 2, field: "name", title: "Nombre de Archivo" },
];

export const GTFSFileUploads = () => {
	const state = {
		gtfsToUpload: useStateNamed(),
		selectedCityId: useStateNamed(-1),
	};
	const selectedCityObj = () =>
		citiesData.res.filter((city) => city.id == state.selectedCityId.val)[0];
	function getForm() {
		const gtfs = state.gtfsToUpload.val;
		const formData = new FormData();
		formData.append("name", selectedCityObj()?.city_name);
		formData.append("GTFS", gtfs);
		return formData;
	}
	const gtfsFilesData = useFetchFunction(GTFSFileAPI.findAll, []);
	const citiesData = useFetchFunction(CityAPI.findAll, []);
	const swals = {
		confirmOverwrite: (fileId) =>
			Swal.fire({
				title: "GTFS Ya existente",
				text: `Se reemplazara el GTFS Previo del Pais ${
					selectedCityObj().city_name
				}`,
				showDenyButton: true,

				confirmButtonText: "Confirmar y Reemplazar",
				denyButtonText: `Cancelar`,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					GTFSFileAPI.overwrite(fileId, getForm());
					Swal.fire(
						"Reemplazado",
						"Se reemplazo el GTFS anterior",
						"success"
					);
				} else if (result.isDenied) {
					Swal.fire("No se reemplazo el GTFS", "", "info");
				}
			}),
	};
	const handlers = {
		changeFile: (e) => {
			state.gtfsToUpload.set(e.target.files[0]);
		},
		clickUpload: async () => {
			const formData = getForm();
			const validationResponse = await GTFSFileAPI.validation({
				filename: `${selectedCityObj()?.city_name}.gtfs.zip`,
			});
			if (!validationResponse.fileExists) {
				await GTFSFileAPI.createFile(formData);
				await gtfsFilesData.getData();
			} else {
				swals.confirmOverwrite(validationResponse.fileId);
			}
		},
		clickUpdateRoutes: async () => {
			Swal.fire({
				title: "Desea actualizar las rutas con los gtfs mostrados?",
				text: "Se detendra el servidor de rutas por unos minutos mientras se actualiza, realice con precaucion",
				showDenyButton: true,

				confirmButtonText: "Actualizar",
				denyButtonText: `No Actualizar`,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					GTFSFileAPI.restartOTP();
					Swal.fire(
						"Se comenzo a actualizar las rutas!",
						"Esta accion tomara unos minutos",
						"success"
					);
				} else if (result.isDenied) {
					Swal.fire("No se actualizaron las rutas", "", "info");
				}
			});
		},
		clickDeleteRow: async (event, rowData) => {
			Swal.fire({
				title: `Desea Borrar Este Archivo :  ${rowData.name} ?`,
				text: "el archivo no se podra recuperar",
				showDenyButton: true,

				confirmButtonText: "Si",
				denyButtonText: `No`,
			}).then(async (result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					await GTFSFileAPI.destroy(rowData.id);
					Swal.fire("Se borro el archivo", "", "success");
					await gtfsFilesData.getData();
				} else if (result.isDenied) {
					Swal.fire("Se conservo el archivo", "", "info");
				}
			});
		},
		clickDownloadRow: async (event, rowData) => {
			const gtfsFileRes=await GTFSFileAPI.download(rowData.id);
			 const blob=await gtfsFileRes.blob()
			generateDownload({fileBlob: blob,fileName:rowData.name})
		},
	};
	return (
		<div>
			<div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4 ">
				<div className="relative flex flex-row flex-wrap min-w-0 w-full mb-6 shadow-lg rounded-lg bg-white p-5">
					<div>
						<div className="w-full xl:w-12/12 mb-12 xl:mb-2 px-4 ">
							<h3> Seleccione Ciudad</h3>
							<TextField
								style={{
									width: "100%",
								}}
								id="filled-select-currency"
								select
								name="selectedCityId"
								value={state.selectedCityId.val}
								onChange={(e) =>
									handleFormChangeStateNamed(
										e,
										state,
										(stato, s) => {}
									)
								}
								variant="outlined"
							>
								{citiesData?.res?.map((aCity) => (
									<MenuItem
										key={aCity.id + aCity.city_name}
										value={aCity.id}
									>
										{aCity.city_name}
									</MenuItem>
								))}
							</TextField>
						</div>
						<div className="w-full xl:w-12/12 mb-12 xl:mb-2 px-4 ">
							<h1>Seleccione archivo GTFS a subir</h1>
							<input onChange={handlers.changeFile} type="file" />
						</div>
						<Grid container justify="space-around">
							<Grid>
								<Button
									onClick={handlers.clickUpload}
									color="primary"
									variant="contained"
								>
									Subir
								</Button>
							</Grid>
							<Grid>
								<Button
									onClick={handlers.clickUpdateRoutes}
									color="primary"
									variant="contained"
								>
									Actualizar Rutas
								</Button>{" "}
							</Grid>{" "}
						</Grid>
					</div>
				</div>
			</div>

			<div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4 mt-10">
				<MaterialTable
					title="Rutas subidas GTFS"
					columns={cols}
					data={gtfsFilesData.res}
					options={options}
					actions={[
						{
							icon: CloudDownload,
							tooltip: "Descargar GTFS",
							onClick: handlers.clickDownloadRow,
						},
						{
							icon: Delete,
							tooltip: "Borrar GTFS",
							onClick: handlers.clickDeleteRow,
						},
					]}
					icons={tableIcons}
				/>
			</div>
		</div>
	);
};

import { TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LogOfTransportAPI } from "api/logOfTransportAPI";
import { DividedDateTimePicker } from "components/DatePickers/DividedDateTimePicker";
import { useStateNamed } from "hooks/useStateNamed";
import React, { useEffect, useMemo } from "react";
import { options, tableIcons } from "utils/tableSettings";
import DateFnsUtils from "@date-io/date-fns";
import MaterialTable from "material-table";
import { ChartTransportReport } from "./components/ChartTransportReport";

const cols = [
	
	{ key: 1, field: "consultedAmount", title: "Cantidad Consultada" },
	{ key: 2, field: "Transport.transport_name", title: "Nombre" },
];

export const TransportReport = () => {
	let dateModel = useMemo(() => ({ init: new Date(), end: new Date() }), []);
	const state = {
		logsData: useStateNamed(),
		chartData:useStateNamed([])
	};
	const defaultValues={
		limit:10
	}
	const auxFunctions = {
		listReport: async () => {
			const loggedTransports = await LogOfTransportAPI.findAll({
				beginDate: dateModel.init.getTime(),
				endDate: dateModel.end.getTime(),
				limit: dateModel["limit"] ?? defaultValues.limit,
			});
			state.logsData.set(loggedTransports);
		},
		createChart: () => {},
	};
	const handlers = {
		clickGetReport: () => {
			if (dateModel.end.getTime() > dateModel.init.getTime()) {
				console.log({ dateModel });
				auxFunctions.listReport();
			} else {
				alert("RANGO INVÁLIDO");
			}
		},
		clickGetChart: async () => {
			if (dateModel.end.getTime() > dateModel.init.getTime()) {
				const logQuery={
					beginDate: dateModel.init.getTime(),
					endDate: dateModel.end.getTime(),
					limit:dateModel["limit"] ?? defaultValues.limit
				}
				console.log({logQuery});
				const transportLogsRes = await LogOfTransportAPI.getCount(logQuery);
				console.log({transportLogsRes});
				state.chartData.set(transportLogsRes);
			} else {
				alert("RANGO INVÁLIDO");
			}
			
		},
	};

	return (
		<div className="flex flex-wrap">
			<div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4 ">
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<div className="relative flex flex-row flex-wrap min-w-0 w-full mb-6 shadow-lg rounded-lg bg-white p-5">
						<h1 className="w-full">Seleccionar Fecha</h1>
						<DividedDateTimePicker init model={dateModel} />
						<DividedDateTimePicker model={dateModel} />

						<TextField
							fullWidth
							type="number"
							variant="outlined"
							label="Cantidad Máxima"
							defaultValue={defaultValues.limit}
							onInput={({ target }) =>
								(dateModel["limit"] = target.value)
							}
						/>

						
						<button
							type="submit"
							style={{ marginTop: 10 }}
							className="cruzero-bg-light-green-01 text-white active:bg-green-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none"
							onClick={handlers.clickGetChart}
						>
							Consultar 
						</button>
					</div>
				</MuiPickersUtilsProvider>
			</div>
			<div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
				<div className="relative flex flex-row flex-wrap min-w-0 w-full mb-6  shadow-lg rounded-lg bg-white p-5">
					<ChartTransportReport transportCountData={state.chartData.val}></ChartTransportReport>
				</div>
			</div>
			<div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4 mt-10">
				<MaterialTable
					title="Consulta de Transportes"
					columns={cols}
					data={state.chartData.val}
					options={options}
					icons={tableIcons}
				/>
			</div>
		</div>
	);
};

import { Button, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { useGlobalDialog } from "hooks/useGlobalDialog";
import { useStateNamed } from "hooks/useStateNamed";
import React from "react";
import { handleFormChangeStateNamed } from "utils/formUtils";
import { PoiTypeAPI } from "api/poiTypes.api";
import forms from "@tailwindcss/forms";
/**
 * type can be create or update
 * @param {*} param0 
 * @returns 
 */
export const DialogContainer = ({attributes={name:'',id:-1},type,reloadData}) => {
	const formState = {
		name: useStateNamed(attributes.name),
	};
	const isCreate=attributes.id==-1;
	const dialog=useGlobalDialog();
	const typeStrategyFunctions={
		/**
		 * Function called when type is "create"
		 */
		create:async()=>{
			await PoiTypeAPI.create({name:formState.name.val});
			reloadData();
			dialog.showWidget(()=><h1>Creado Con Exito</h1>);
		},
		/**
		 * Function called when type is "update"
		 */
		update:async()=>{
			await PoiTypeAPI.update({idToEdit:attributes.id,name:formState.name.val});
			reloadData();
			dialog.showWidget(()=><h1>Actualizado Con Exito</h1>);
		}
	}
	const handlers={
		clickCancel:dialog.hide,
		clickSave:()=>{
			typeStrategyFunctions[type]();
		}
	}
	return (
		<div>
               <DialogTitle id="alert-dialog-title">
          {isCreate?"Nueva Categoria":'Actualizar Categoria'}
        </DialogTitle>
        <DialogContent>
        <TextField
				id="outlined-basic"
				label="Nombre"
				name="name"
				variant="outlined"
				value={formState.name.val}
				onChange={(e) => handleFormChangeStateNamed(e, formState)}
			/>
        </DialogContent>
		<DialogActions>
          <Button variant="contained" onClick={handlers.clickCancel}>Cancelar</Button>
          <Button color="primary" variant="contained" onClick={handlers.clickSave} autoFocus>
            Guardar
          </Button>
        </DialogActions>
		</div>
	);
};

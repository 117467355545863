import React from "react";
import Chart from "chart.js";

//import { app as MOVIL, web as WEB } from 'assets/json/views.json'
import { consultaDestinoAPI } from "api/consultaDestino.api";
import { useFetchFunction } from "hooks/useFetchFunction";

export default function CardLineChart() {
  const {res}=useFetchFunction(()=> consultaDestinoAPI.getTodayVisits({isGroupPerHour:false}))
  const configureChart=async () => {
    const todayVisits=await consultaDestinoAPI.getTodayVisits({isGroupPerHour:true});
    const {data:{WEB,MOVIL}}=todayVisits;
    let { labelsArray, webArray, mobileArray } = getParsedArraysForChart(WEB, MOVIL);
    const config = {
      type: "line",
      data: {
        labels:labelsArray ,
        datasets: [
          {
            fill: false,
            label: 'Web',
            borderColor: "rgb(169, 216, 0)",
            backgroundColor: "rgb(169, 216, 0)",
            data: webArray.map(({ visits}) => visits),
          },
          {
            fill: false,
            label: 'App',
            borderColor: "#fff",
            backgroundColor: "#fff",
            data: mobileArray.map(({ visits }) => visits),
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Today Views",
          fontColor: "white",
        },
        legend: {
          labels: {
            fontColor: "white",
          },
          align: "center",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: false,
                fontColor: "white",
                labelString: "Month",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    var ctx = document.getElementById("line-chart").getContext("2d");
    window.myLine = new Chart(ctx, config);
  }
  React.useEffect(()=>{
    configureChart();
  }, []);

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded cruzero-bg-green-01">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
              Overview
              </h6>
            <h2 className="text-white text-xl font-semibold">
              {Number.parseInt(res?.data?.MOVIL?.[0]?.visits)+Number.parseInt(res?.data?.WEB?.[0]?.visits)} Calculos de Ruta Hoy
            </h2>
          </div>
        </div>
      </div>
      <div className="p-4 flex-auto">
        {/* Chart */}
        <div className="relative h-350-px">
          <canvas id="line-chart"></canvas>
        </div>
      </div>
    </div>
  );
}
function getParsedArraysForChart(WEB, MOVIL) {
  let labelsArray = [];
  for (let index = 0; index < 24; index++) {
    labelsArray.push(index);
  }
  let webArray = [];
  let mobileArray = [];
  parseMissedHoursForChart(WEB, webArray);
  parseMissedHoursForChart(MOVIL, mobileArray);
  return { labelsArray, webArray, mobileArray };
}

function parseMissedHoursForChart(DEVICE, deviceArray) {
  for (let index = 0; index <= 24; index++) {

    if (!DEVICE.some(({ hour }) => hour == index)) {
      deviceArray.push({ hour: index, visits: 0 });
    } else {
      deviceArray.push(DEVICE.filter(({ hour }) => hour == index)[0]);
    }

  }
}


import { Grid } from "@material-ui/core";
import { contributionAPI } from "api/contribution.api";
import { useModalAlertForm } from "hooks/useModalAlertForm";
import { useState } from "react";
import { useFetch } from "../../../hooks/useState";
import DataTableContainer from "./Containers/DataTableContainer";
import ModalFormMap from "./Containers/ModalFormMap";



const Contributions = () => {
	const modalProps = useModalAlertForm({
		id: -1,
		name: "",
		description: "",
		website: "",
		direction: "",
		phone_number: "",
		poi_type_id: "",
	});
	const [selectedContribution, setSelectedContribution] = useState({
		id: "",
		shape_geom: {
			crs: {
				type: "",
				properties: {
					name: "",
				},
			},
			type: "",
			coordinates: [],
		},
	});
	const { rows, reloadPage: reloadData } = useFetch(
		`${contributionAPI.urls.all}`
	);
	


	return (
		<>
			<Grid container spacing={1} direction="row">
				<Grid item>
					{/* <Button
						disableElevation
						color="primary"
						variant="contained"
						startIcon={<Add />}
						onClick={modalProps.handleOpen}
					>
						Nueva Contribucions
					</Button> */}
				</Grid>
				<Grid item xs={12}>
					<DataTableContainer
						rows={rows.transport_data}
						reloadPage={reloadData}
						modalProps={modalProps}
						setSelectedContribution={setSelectedContribution}
					/>
				</Grid>
				
			</Grid>
			<ModalFormMap
				selectedContribution={selectedContribution}
				{...modalProps}
				reloadTable={reloadData}
			/>
			
		</>
	);
};

export default Contributions;

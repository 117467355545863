/**
 * Generates a download from browser res
 * @param {*} param0.fileBlob the file that will be downloaded, if res.download used in NodeJS it should be the response
 */
export const generateDownload = ({ fileBlob,fileName }) => {
	const url = window.URL.createObjectURL(new Blob([fileBlob]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", fileName);
	document.body.appendChild(link);
	link.click();
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  .ModalFormMap_imageItem__3_--a {\n    display: flex;\n    margin: 10px 0;\n\n  }\n  .ModalFormMap_imageItem__btnWrapper__6OmNf {\n    display: flex;\n    flex-direction: column;\n    margin-left: 10px;\n  }\n  button {\n    height: 40px;\n  }\n  ", "",{"version":3,"sources":["webpack://src/views/admin/Places/Containers/ModalFormMap.module.css"],"names":[],"mappings":";EACE;IACE,aAAa;IACb,cAAc;;EAEhB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;EACA;IACE,YAAY;EACd","sourcesContent":["\n  .imageItem {\n    display: flex;\n    margin: 10px 0;\n\n  }\n  .imageItem__btnWrapper {\n    display: flex;\n    flex-direction: column;\n    margin-left: 10px;\n  }\n  button {\n    height: 40px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageItem": "ModalFormMap_imageItem__3_--a",
	"imageItem__btnWrapper": "ModalFormMap_imageItem__btnWrapper__6OmNf"
};
export default ___CSS_LOADER_EXPORT___;

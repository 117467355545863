import { useState, createRef } from "react";
import { createPopper } from "@popperjs/core";

import { Link } from 'react-router-dom'
import zonesIconURL from 'assets/icons/ads_icon.png'
import visitsIconURL from 'assets/icons/visits_icon.png'
import {SidebarDropdown} from 'components/Dropdowns/SidebarDropdown'
import destinationIconURL from 'assets/icons/destination_icon.png'
import transportIconURL from 'assets/icons/transports_icon.png'
const reportes = [
  ['zonas', 'fas fa-shapes',zonesIconURL],
  // ['visitas', 'fas fa-eye',visitsIconURL],
  ['destinos', 'fas fa-flag-checkered',destinationIconURL],
  ['transportes', 'fas fa-flag-checkered',transportIconURL],
  ['paradas', 'fas fa-flag-checkered',transportIconURL],
  ['Lugares', 'fas fa-flag-checkered',transportIconURL],
]

const isSelected = (path) => {
  return window.location.href.indexOf(`/admin/reportes/${path}`) !== -1
  ? ["text-green-500 hover:text-green-400", "text-green-400"]
  : ["text-blueGray-700 hover:text-blueGray-500", "opacity-75"]
}

export const ReportSidebarDropdown = ({ icon,iconUrl, color, style }) => {

  return(
    <SidebarDropdown key='route'  baseRoute={'/admin/reportes'} className={style} color={color} title={'Reportes'} icon={icon} iconUrl={iconUrl} subMenuArray={reportes} ></SidebarDropdown>
  )

};


/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react'
import { useHistory } from 'react-router'
import { ToggleButton } from '@material-ui/lab'
import { Button, ButtonGroup } from '@material-ui/core'
import { Delete, Edit, Visibility } from '@material-ui/icons'

import ModalFormMap from './DialogFormContainer'
import { useModal } from '../../../../hooks/useState'
import { URL, destroy } from '../../../../utils/formUtils'
import { options, tableIcons } from '../../../../utils/tableSettings'
import DataTableCustom from 'components/Material/DataTables/DataTableCustom'
import { url } from 'api/api.utils'
import { PoiAPI } from 'api/poi.api'
import { StateSelect } from 'components/Selects/StateSelect'
import Swal from 'sweetalert2'

const cols = [
    'id',
    'poi_name',
    'poi_contributionstatus'
]

const DataTableContainer = ({ rows, reloadPage,modalProps}) => {
    const [model, setModel] = useState({})
    const { open, openModal, closeModal } = useModal()

    const onView = (rowData) => {
        setModel(rowData)
        console.log(rowData)
    }
    const onEdit = async (rowData) => {
       
        const currentPoi=await PoiAPI.getOne(rowData.id);
        
        const newFormData={
        id: rowData.id,
		name: currentPoi.data.poi_name,
		description: currentPoi.data.poi_description,
		website: currentPoi.data.poi_website,
		direction: currentPoi.data.poi_direction,
		phone_number: currentPoi.data.poi_phone_number,
		poi_type_id: currentPoi.data.PoiTypeId,
        city_id: currentPoi.data.CityId,
		lat: currentPoi.data.poi_lat,
        lng: currentPoi.data.poi_lon,
        pictures:currentPoi.data.Pictures
        }
        console.log({newFormData,currentPoi});
                modalProps.setFormData(newFormData);
                modalProps.handleOpen();
    }
    const onDelete=async(rowData)=>{
        const res=await PoiAPI.destroy(rowData.id)
        reloadPage();
    }
    const callbacks={
        /**
         * 
         * @param {*} currentItem the object to be afected
         * @param {*} contributionStateNamed  the stateNamed of the contribution
         */
        changeContributionStateWithAPI:async (currentItem,contributionStateNamed,valueChanged)=>{
            await PoiAPI.update(currentItem.id,{contributionStatus:valueChanged});
            reloadPage()
            Swal.fire('Estado Cambiado Exitosamente')
        }
    }
    const actionColumnFunctions={
        stateContribution:({colName,obj})=>{
            if(colName=="poi_contributionstatus"){
                
                obj.render=(data)=>{
                    //Data is the current object
                    return <StateSelect initialContributionState={data[colName]} onChangeStateCallback={(contributionStateNamed,valueChanged)=>{callbacks.changeContributionStateWithAPI(data,contributionStateNamed,valueChanged)}}></StateSelect>;
                }
            }
        }
    }
    return (
        <>
            <DataTableCustom
                MaterialTableProps={{ title: "Lugares", key: 'MaterialLugar' }}
                cols={cols}
                rowsData={rows}
                actionColumn
                titlesReplace={{poi_contributionstatus:'Estado de Contribucion'}}
                actionColumnFunctions={{  onEdit, onDelete }}
                customColumnRenders={[actionColumnFunctions.stateContribution]}
            />
        </>
    );
}

export default DataTableContainer
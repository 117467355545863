import { defaultHeaders, url } from "./api.utils";
import { genericDestroy } from "./api.utils";
export const PoiTypeAPI = {
	urls:{
		all:`${url}poi_type/all`
	},
	create: async ({name}) => {
		const body ={name};
		try {
			const res = await fetch(`${url}poi_type/create`, {
				method: 'POST',
				headers: defaultHeaders,
                body: JSON.stringify(body),
			});
			const data = await res.json();
			return data;
		} catch (error) {
			console.log(error);
		}
	},
	update:async({idToEdit,name})=>{
		try{
			const body={name}
			const res=await fetch(`${url}poi_type/update/${idToEdit}`,{
				method:'PUT',
				headers:defaultHeaders,
				body:JSON.stringify(body)
			})
			const data=await res.json();
			return data;
		}catch(error){
			console.log(error);
		}
	},
	destroy: async (id) => {
		return await genericDestroy("poi_type/delete", id);
	},
};
import { useState } from "react";
/**
 * 
 * @returns {{open,handleOpen,handleClose}}
 */
export const useModalAlertDialog=()=>{
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
      return {open,handleOpen,handleClose}
}
import { useState } from "react";

export const useMultiUploader=()=>{
    const [dataSources, setDataSources] = useState([])
    const [visible, setVisible] = useState(false);
  const handleSetVisible = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };
  const onUpload = (data) => {
    console.log("Upload files", data);
  };
  const onSelect = (data) => {
    console.log("Select files", data);
  };
  const onRemove = (id) => {
    console.log("Remove image id", id);
  };
  return {dataSources,onRemove,onUpload,onSelect,hideModal,visible,handleSetVisible}
}
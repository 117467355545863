import { LogOfTransportAPI } from "api/logOfTransportAPI";
import { Chart } from "chart.js";
import React, { useEffect, useRef } from "react";
import randomColor from "randomcolor";
import { app } from "assets/json/views.json";
import { useStateNamed } from "hooks/useStateNamed";
export const ChartStopReport = ({ transportCountData = [] }) => {
	const areLogsEmpty = () => transportCountData.length == 0;
	const chartRef = useRef();
	let chartObj=useStateNamed({});
	const createChart = async () => {
		const parsedTransportNames = [];
		const parsedAmounts = [];

		const chartData = {
			labels: parsedTransportNames,
			datasets: [
				{
					label: "Consultas de Paradas",
					backgroundColor: "#000",
					borderColor: "#ffff",
					data: parsedAmounts,
				},
			],
		};
		const config = {
			type: "pie",
			data: chartData,
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: "top",
					},
					title: {
						display: true,
						text: "Chart.js Pie Chart",
					},
				},
			},
		};

		//  var ctx = document.getElementById("pie-chart").getContext("2d");
		var ctx = chartRef.current.getContext("2d");

        chartObj.set(new Chart(ctx, config))
	};
	const updateChart = () => {
		const parsedTransportNames = transportCountData.map(
			(item) => item?.Stop?.id
		);
		const parsedAmounts = transportCountData.map(
			(item) => item?.consultedAmount
		);

		const chartData = {
			labels: parsedTransportNames,
			datasets: [
				{
					label: "Consultas de Paradas",
					backgroundColor: "#000",
					borderColor: "#ffff",
					data: parsedAmounts,
				},
			],
		};
		const config = {
			type: "pie",
			data: chartData,
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: "top",
					},
					title: {
						display: true,
						text: "Chart.js Pie Chart",
					},
				},
			},
		};

		//  var ctx = document.getElementById("pie-chart").getContext("2d");
		var ctx = chartRef.current.getContext("2d");
		
        chartObj.val.data.labels=parsedTransportNames
        chartObj.val.data.datasets=[
            {
                label: "Consultas de Paradas",
                backgroundColor: randomColor({
                    count: parsedTransportNames.length,
                    hue: 'random'
                 }),
                borderColor: "#ffff",
                data: parsedAmounts,
            },
        ]
        chartObj.val.update()
	};
	useEffect(() => {if (areLogsEmpty()) {
        createChart();
    }
		
	}, []);
	useEffect(() => {
		if (!areLogsEmpty()) {
			updateChart();
		}
		return () => {};
	}, [transportCountData]);
	return (
		<>
			<h2>Grafica</h2>
			<canvas ref={chartRef} id="line-chart"></canvas>
		</>
	);
};

import { useEffect, useState } from "react";

/**
 * You can send a function to this useFetch
 * recommended use when something is static data and will not be fetched two times
 * and also to have more order having the function in api consumer modules
 * @author Pablo Tardio Ventura
 * @param  functionToFetch  aync function without parenthesis (no call)
 * @param  defaultState  the value that will be the request initially
 * @returns 
 */
export const useFetchFunction = (functionToFetch=()=>{},defaultState={}) => {
	const [res, setRes] = useState(defaultState);

	useEffect(() => {
		fetchMyFunction();
		
	}, []);
	const fetchMyFunction =async () => {
		await getData()
	};
	const getData=async()=>{
		try {
			
			const data = await functionToFetch();
			
			setRes(data);
		} catch (error) {
			console.log(error);
		}
	}
	return {
		res, getData
	};
};

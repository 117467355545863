/*eslint-disable*/
import { useState } from "react";
import { Link } from "react-router-dom";

import UserDropdown from "components/Dropdowns/UserDropdown"
import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js"
import adsIconURL from 'assets/icons/ads_icon.png'
import categoriesIconURL from 'assets/icons/categories_icon.png'
import panelIconURL from 'assets/icons/panel_icon.png'
import placesIconURL from 'assets/icons/places_icon.png'
import reportsIconURL from 'assets/icons/reports_icon.png'
import settingsIconURL from 'assets/icons/settings_icon.png'
import transportsIconURL from 'assets/icons/transports_icon.png'

import webAdmin1IconURL from 'assets/icons/web-admin-08.png'
import webAdmin2IconURL from 'assets/icons/web-admin-13.png'
import {ReportSidebarDropdown} from 'layouts/admin/components/Dropdowns/Report.SidebarDropdown'  

const isSelected = (path) => {
  return window.location.href.indexOf(`/admin/${path}`) !== -1
    ? ["text-green-500 hover:text-green-400", "text-green-400"]
    : ["text-blueGray-700 hover:text-blueGray-500", "opacity-75"]
}

const routes = [
  ["dashboard", "fas fa-tv",panelIconURL],
  
  //["contenidos","fa fa-stream",categoriesIconURL],
  ["categorias", "fa fa-stream",categoriesIconURL],
  ["Lugares", "fas fa-building",placesIconURL],
  ["transportes", "fa fa-bus",transportsIconURL],
  ["gtfs", "fa fa-bus",transportsIconURL],
  // ["taxis", "fa fa-taxi"],
  // ["publicidad", "fa fa-ad"],
  
  ["reportes", "fas fa-chart-pie",reportsIconURL],
]

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = useState("hidden");
  return <>
    <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
      <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        {/* Toggler */}
        <button
          className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
          type="button"
          onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
        >
          <i className="fas fa-bars"></i>
        </button>
        {/* Brand */}
        <Link
          className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
          to="/"
        >
          Admin Panel
          </Link>
        {/* User */}
        <ul className="md:hidden items-center flex flex-wrap list-none">
          <li className="inline-block relative">
            <NotificationDropdown />
          </li>
          <li className="inline-block relative">
            <UserDropdown />
          </li>
        </ul>
        {/* Collapse */}
        <div
          className={
            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
            collapseShow
          }
        >
          {/* Collapse header */}
          <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
            <div className="flex flex-wrap">
              <div className="w-6/12">
                <Link
                  className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                  to="/"
                >
                  Notus React
                  </Link>
              </div>
              <div className="w-6/12 flex justify-end">
                <button
                  type="button"
                  className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          {/* Form */}
          <form className="mt-6 mb-4 md:hidden">
            <div className="mb-3 pt-0">
              <input
                type="text"
                placeholder="Search"
                className="border-0 px-3 py-2 h-12 border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
              />
            </div>
          </form>

          {/* Divider */}
          <hr className="my-4 md:min-w-full" />
          {/* Heading */}
          <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
            Administrar Cruzero
            </h6>
          {/* Navigation */}

          <ul className="md:flex-col md:min-w-full flex flex-col list-none">
            {
              routes.map(([route, icon,iconUrl],index) => {
               
                
                if (route === 'reportes') {
                  return <ReportSidebarDropdown key={index}
                    icon={icon} iconUrl={iconUrl}route={route}  
                  />
                }
                let [style, color] = isSelected(route)
                return <li key={route} className="items-center">
                  <Link to={`/admin/${route}`} className={`text-xs uppercase py-3 font-bold block ${style}`}>
                  <img style={{width:'2em',height:'100%',display:'inline'}} src={`${iconUrl}`}  alt="" /> <i className={` mr-2 text-sm ${color}`}>{` ${route}`}</i>
                  </Link>
                </li>
              })
            }
          </ul>
        </div>
      </div>
    </nav>
  </>
}

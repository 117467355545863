import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
	useTheme,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { LineAPI } from "api/line.api";
import Map from "components/Map/Map";
import { useStateNamed } from "hooks/useStateNamed";
import React from "react";
import { handleFormChangeStateNamed } from "utils/formUtils";

export const LineAlertMap = ({
	id,
	tripId,
	selectedContribution,
	setMapData,
	onClickSave = () => {},
}) => {
	const formState = {
		id: useStateNamed(id),
		trip_id: useStateNamed(tripId),
	};
	const handlers = {
		clickDownload: async () => {
			const fileName = "contribucion" + selectedContribution?.id;
			const json = JSON.stringify(selectedContribution);
			const blob = new Blob([json], { type: "application/json" });
			const href = await URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = href;
			link.download = fileName + ".json";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		onClickSave: async () => {
			await LineAPI.update(id, { trip_id: formState.trip_id.val });
			onClickSave();
		},
	};
	return (
		<>
			<DialogTitle id="alert-dialog-title">
				{id ? "Editando " : ""}
				{`Ruta de Transporte: ${id}`}{" "}
			</DialogTitle>
			<DialogContent>
				<Grid container style={{ width: "60rem" }} justify="center">
					{id && (
						<Grid item md={12}>
							<Grid container>
								<Grid item md={6}>
									<TextField
										value={formState.trip_id?.val}
										variant="outlined"
										label="Linea GTFS (trip_id)"
										name="trip_id"
										onChange={(e) =>
											handleFormChangeStateNamed(e, formState)
										}
									/>
								</Grid>
							</Grid>
						</Grid>
					)}
					<Grid item md={12}>
						<Map
							contributionGeoJSON={true}
							contributionGeoJSONData={selectedContribution}
							isEditable={true}
							editableAction={setMapData}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				{!id && (
					<Button
						onClick={handlers.clickDownload}
						autoFocus
						startIcon={<CloudDownload />}
						variant="contained"
						color="primary"
					>
						{"Descargar"}
					</Button>
				)}
				{id && (
					<Button
						onClick={handlers.onClickSave}
						autoFocus
						variant="contained"
						color="primary"
					>
						{"Guardar"}
					</Button>
				)}
			</DialogActions>
		</>
	);
};

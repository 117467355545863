
import { GlobalDialog } from "components/Material/Dialogs/GlobalDialog";
import { useStateNamed } from "hooks/useStateNamed";
import { createContext } from "react";



export const GlobalDialogContext = createContext({
	open: { val: false, set: () => {} },
	children: { val: ()=><></>, set: () => {} },
});

export const GlobalDialogContextProvider = ({ children }) => {
	const contextState = {
		open: useStateNamed(false),
		children: useStateNamed(()=><></>),
	};
	return (
		<GlobalDialogContext.Provider value={contextState}>
			<GlobalDialog/>
			{children}
		</GlobalDialogContext.Provider>
	);
};

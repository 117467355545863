/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import { PoiTypeAPI } from 'api/poiTypes.api'
import DataTableCustom from 'components/Material/DataTables/DataTableCustom'
import { useGlobalDialog } from 'hooks/useGlobalDialog'
import { useState } from 'react'
import { DialogContainer } from './DialogContainer'



const cols = [
    'id',
    'type_name',
]




const DataTableContainer = ({ rows, reloadPage }) => {
    const [model, setModel] = useState({})
    const globalDialog=useGlobalDialog();
   
   
    const onEdit=(rowData)=>{
       
        globalDialog.showWidget(()=><DialogContainer attributes={{name:rowData.type_name,id:rowData.id}} type='update' reloadData={reloadPage}/>)
    }
    const onDelete=async (rowData)=>{
        await PoiTypeAPI.destroy(rowData.id);
        reloadPage();
        globalDialog.showWidget(()=><h1>Eliminado exitosamente</h1>)
    }
    return (
        <>
            <DataTableCustom
                MaterialTableProps={{title:"Lugares",key:'MaterialLugar'}}
                cols={cols}
                rowsData={rows}
                actionColumn
                actionColumnFunctions={ {onEdit,onDelete} }
            />
            
            
        </>
    );
}

export default DataTableContainer
import "date-fns";

import DateFnsUtils from "@date-io/date-fns";

import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { AccessTime } from "@material-ui/icons";
import {
	KeyboardDatePicker,
	KeyboardTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { useDate } from "hooks/useDate";

import Map from "components/Map/Map";
import Matrix from "components/matrix/matrix";
import { useFetchFunction } from "hooks/useFetchFunction";
import { zoneAPI } from "api/zones.api";
import { Grid, Snackbar, TextField } from "@material-ui/core";
import { useStateNamed } from "hooks/useStateNamed";
import { handleFormChangeStateNamed } from "utils/formUtils";
import { DividedDateTimePicker } from "components/DatePickers/DividedDateTimePicker";

const DateTimePicker = ({ init = false, model }) => {
	const { date, handleDate } = useDate(init, model);

	return (
		<>
			<KeyboardDatePicker
				margin="normal"
				variant="inline"
				format="dd/MM/yyyy"
				value={date}
				onChange={handleDate}
				style={{ width: "50%" }}
				label={`Fecha ${init ? "Inicial" : "Final"}`}
			/>
			<KeyboardTimePicker
				className="w-full"
				margin="normal"
				variant="inline"
				value={date}
				onChange={handleDate}
				style={{ width: "50%" }}
				keyboardIcon={<AccessTime />}
				label={`Hora ${init ? "Inicial" : "Final"}`}
			/>
		</>
	);
};

const Report = () => {
	const { res: countZones } = useFetchFunction(zoneAPI.getCount, 0);
	const [model, setModel] = useState({ init: new Date(), end: new Date() });
	const zoneFormState = {
		origin: useStateNamed(0),
		destination: useStateNamed(0),
		focusedInput: useStateNamed("none"),
	};

	const matrixTableState = {
		customOriginDestinationZones: useStateNamed([]),
	};
	const [row, setRow] = useState(1);
	const [col, setCol] = useState(1);

	const [limit, setLimit] = useState(10);
	const [reload, setReload] = useState(false);

	const handlers = {
		clickZoneCalculation: () => {
			setLimit(2);
			console.log(
				zoneFormState?.origin?.val,
				zoneFormState?.destination?.val
			);
			matrixTableState.customOriginDestinationZones.set([
				parseInt(zoneFormState?.origin?.val),
				parseInt(zoneFormState?.destination?.val),
			]);
		},
		clickButtonZonesClean: () => {
			setLimit(10);
			matrixTableState.customOriginDestinationZones.set([]);
			zoneFormState.origin.set(0);
			zoneFormState.destination.set(0);
		},
		clickAZone: useCallback(
			(id) => {

				auxFunctions.verifyFocusAndWrite(id);
				
			},
			[zoneFormState.focusedInput.val]
		
		),
		focusInputRegistrer: (e) => {
			zoneFormState.focusedInput.set(e.target.name);
			console.log("focused", e.target.name);
		},
	};
	const auxFunctions = {
		verifyFocusAndWrite: (id) => {
			const inputState = {
				origin: zoneFormState.origin,
				destination: zoneFormState.destination,
			};
			const focusedInput = zoneFormState.focusedInput.val;

			inputState?.[focusedInput]?.set(id);
			zoneFormState.focusedInput.restart();
		},
	};
	return (
		<>
			<div className="flex flex-wrap">
				<div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<div className="relative flex flex-row flex-wrap min-w-0 w-full mb-6 shadow-lg rounded-lg bg-white p-5">
							{/* .../... */}
							<h1 className="w-full">Seleccionar Fecha</h1>
							<DividedDateTimePicker
								init
								model={model}
								setModel={setModel}
							/>
							<DividedDateTimePicker model={model} setModel={setModel} />
							<button
								style={{ marginTop: 10 }}
								className="cruzero-bg-light-green-01 text-white active:bg-green-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none"
								onClick={() => {
									let json = {
										init: model.init.getTime(),
										end: model.end.getTime(),
									};
									if (json.init < json.end) {
										json = JSON.parse(JSON.stringify(json));
										setModel({
											init: new Date(json.init),
											end: new Date(json.end),
										});
										setReload(true);
										console.log("model", model);
									} else {
										alert("Fechas No Válidas !!");
									}
								}}
							>
								Consultar
							</button>
						</div>
					</MuiPickersUtilsProvider>
					<div className="relative flex flex-row flex-wrap min-w-0 w-full mb-6 shadow-lg rounded-lg bg-white p-5">
						<Grid container justify="space-around">
							{" "}
							{/* .../... */}
							<h1 className="w-full">Seleccionar Zona</h1>
							<Grid item xs={12}>
								{" "}
								<TextField
									onFocus={handlers.focusInputRegistrer}
									type="number"
									value={zoneFormState.origin.val}
									onChange={(e) => {
										handleFormChangeStateNamed(e, zoneFormState);
									}}
									id="outlined-basic"
									name="origin"
									label="Zona Origen"
								/>
							</Grid>
							<Grid item xs={12}>
								{" "}
								<TextField
									onFocus={handlers.focusInputRegistrer}
									type="number"
									value={zoneFormState.destination.val}
									onChange={(e) => {
										handleFormChangeStateNamed(e, zoneFormState);
									}}
									id="outlined-basic"
									label="Zona Destino"
									name="destination"
								/>
							</Grid>
							<Grid item>
								<button
									style={{ marginTop: 10 }}
									className="cruzero-bg-light-green-01 text-white active:bg-green-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none"
									onClick={handlers.clickZoneCalculation}
								>
									Consultar
								</button>
							</Grid>
							<Grid item>
								<button
									style={{ marginTop: 10 }}
									className="cruzero-bg-light-green-01 text-white active:bg-green-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none"
									onClick={handlers.clickButtonZonesClean}
								>
									Limpiar
								</button>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={zoneFormState.focusedInput.val != "none"}
						message="Seleccione Zona"
						key={'top center'}
					/>
					<Map overlayedUVZones onClickAZone={handlers.clickAZone} />
				</div>
				<br />
				<div
					style={{ marginTop: 10, padding: "1% 2%", background: "white" }}
					className="w-full flex justify-center flex-row flex-wrap"
				>
					<strong className="w-full">
						<h1 style={{ fontSize: "130%" }}>Matriz de Zonas</h1>
					</strong>
					<Matrix
						model={model}
						col={col}
						row={row}
						limit={limit}
						reload={reload}
						count={countZones}
						setCol={setCol}
						setRow={setRow}
						customOriginDestinationZones={
							matrixTableState.customOriginDestinationZones.val
						}
						setLimit={setLimit}
						setReload={setReload}
					/>
				</div>
			</div>
		</>
	);
};

export default Report;

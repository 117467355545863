import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import 'app.css'
import "assets/styles/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// layouts
import Auth from "layouts/Auth.js";
import Admin from "layouts/admin/Admin.js";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { GlobalDialogContextProvider } from "contexts/GlobalDialogContext";

const MyApp = () => {
    const theme = createMuiTheme({
        palette: {
          primary: {
            main: 'rgb(169, 216, 0);',
            contrastText: '#FFF',
            light: '#77ffa1',
            dark: '#009f44'
    
          },
          secondary: {
            // This is green.A700 as hex.
            main: '#16d190',
            contrastText: '#0d2b12',
            light: '#77ffa1',
            dark: '#009f62'
          },
        }
      })
      
    return   <BrowserRouter > <ThemeProvider theme={theme}>  <GlobalDialogContextProvider>
   
        <Switch>
            {/* add routes with layouts */}
            
            <Route path="/auth" component={Auth} />
            <Route path="/admin" component={Admin} />
            {/* add routes without layouts */}
           
            <Route exact path="/"><Redirect to="/auth" /></Route>
            {/* add redirect for first page */}
            <Redirect from="*" to="/auth" />
        </Switch></GlobalDialogContextProvider></ThemeProvider>
      
    </BrowserRouter>
}

export default MyApp
import { genericGet } from "./api.utils";

export const LogOfStopAPI={
    findAll: async ({beginDate,endDate,limit=1000}) => {
		try {
			const res = await genericGet(`logsOfConsultedStop`, {
				beginDate,endDate,limit
			});
			return res;
			
		} catch (error) {
			console.log({error});
		}
	},getCount: async ({ isGroupByStop = true ,beginDate,endDate,limit=1000}) => {
		try {
			const res = await genericGet(`logsOfConsultedStop/count`, {beginDate,endDate,limit,
				isGroupByStop
			});
			return res;
		} catch (error) {
			console.log({error});
		}
	},
}
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { useHistory } from "react-router";
import { ToggleButton } from "@material-ui/lab";
import { Button, ButtonGroup } from "@material-ui/core";
import { Delete, Edit, Visibility } from "@material-ui/icons";

import ModalFormMap from "./ModalFormMap";
import { useModal } from "../../../../hooks/useState";
import { URL, destroy } from "../../../../utils/formUtils";
import { options, tableIcons } from "../../../../utils/tableSettings";

import DataTableCustom from "components/Material/DataTables/DataTableCustom";
import { url } from "api/api.utils";
import { PoiAPI } from "api/poi.api";
import { StateSelect } from "components/Selects/StateSelect";
import Swal from "sweetalert2";
import MaterialTable from "material-table";
import { TransportAPI } from "api/transport.api";
import { useGlobalDialog } from "hooks/useGlobalDialog";
import { LineAlertMap } from "./Lines/AlertMap";
import { DataTableLines } from "./Lines/DataTable.Lines";
import { useStateNamed } from "hooks/useStateNamed";
import { TransportBasicDetails } from "./TransportBasicDetails";
import { useModalAlertDialog } from "hooks/useModalAlertDialog";
import { useModalAlertForm } from "hooks/useModalAlertForm";
const cols = ["id", "transport_name", "transport_contributionstatus"];

const DataTableContainer = ({ rows, reloadPage, title }) => {
	const [model, setModel] = useState({});
	const baseTransportFormData = {
		picture: useStateNamed(""),
		name: useStateNamed(""),
	};
	const dialog = useGlobalDialog();
	const state = {
		selectedRowDataDetail: useStateNamed({ isEmpty: true }),
	};
	const listTransportDetail = async (transportId) => {
		const aTransport = await TransportAPI.findOne(transportId);
		console.log(aTransport?.data[0]);
		state.selectedRowDataDetail.set(aTransport?.data[0]);
	};
	const onView = async (rowData) => {
		await listTransportDetail(rowData.id);
		// dialog.showWidget(() => (
		// 	<TransportsAlertMap
		// 		transportName={rowData.transport_name}
		// 		linesData={aTransport?.data?.[0]?.Lines}
		// 		selectedContribution={aTransport?.data?.[0]?.Lines?.[0]?.Shape}
		// 	/>
		// ));
		//  setSelectedContribution(rowData?.Lines?.[0]?.Shape)
		//console.log(rowData?.Lines?.[0]?.Shape)
	};
	const onEdit = async (rowData) => {
		const aTransport = (await TransportAPI.findOneForEdition(rowData.id))
			?.data[0];
		const transportData = {
			id: aTransport.id,
			name: aTransport.transport_name,
			pictures: aTransport.Pictures,
			routeId: aTransport.route_id,
		};

		dialog.showWidget(
			<TransportBasicDetails
				attributes={transportData}
				onClickSave={reloadPage}
			/>
		);
	};
	const onDelete = async (rowData) => {
		const res = await PoiAPI.destroy(rowData.id);
		reloadPage();
	};
	const handlers = {
		clickBack: () => {
			state.selectedRowDataDetail.restart();
		},
	};
	const callbacks = {
		/**
		 *
		 * @param {*} currentItem the object to be afected
		 * @param {*} contributionStateNamed  the stateNamed of the contribution
		 */
		changeContributionStateWithAPI: async (
			currentItem,
			contributionStateNamed,
			valueChanged
		) => {
			const res = await TransportAPI.update(currentItem.id, {
				transport_contributionstatus: valueChanged,
			});
			console.log(res);
			reloadPage();
			Swal.fire("Estado Cambiado Exitosamente");
		},
	};
	const actionColumnFunctions = {
		stateContribution: ({ colName, obj }) => {
			if (colName == "transport_contributionstatus") {
				obj.render = (data) => {
					//Data is the current object
					return (
						<StateSelect
							initialContributionState={data[colName]}
							onChangeStateCallback={(
								contributionStateNamed,
								valueChanged
							) => {
								callbacks.changeContributionStateWithAPI(
									data,
									contributionStateNamed,
									valueChanged
								);
							}}
						></StateSelect>
					);
				};
			}
		},
	};
	const columns = [
		{ title: "id", field: "id" },
		{ title: "transport_name", field: "transport_name" },
		{
			title: "transport_contributionstatus",
			field: "transport_contributionstatus",
			render: (rowData) => (
				<StateSelect
					initialContributionState={rowData}
					onChangeStateCallback={(
						contributionStateNamed,
						valueChanged
					) => {
						callbacks.changeContributionStateWithAPI(
							rowData,
							contributionStateNamed,
							valueChanged
						);
					}}
				></StateSelect>
			),
		},
	];
	return (
		<>
			{state.selectedRowDataDetail.val.isEmpty ? (
				<DataTableCustom
					MaterialTableProps={{
						title,
						key: "MaterialLugar",
						options: {
							filtering: true,
						},
					}}
					cols={cols}
					rowsData={rows}
					actionColumn
					actionColumnFunctions={{ onView, onEdit }}
					customColumnRenders={[actionColumnFunctions.stateContribution]}
				/>
			) : (
				<>
					<Button
						onClick={handlers.clickBack}
						variant="contained"
						color="primary"
					>
						{" "}
						Volver a Lista de Transportes
					</Button>
					<DataTableLines
						reloadPage={() =>
							listTransportDetail(state.selectedRowDataDetail.val.id)
						}
						rows={state.selectedRowDataDetail.val.Lines}
						title={`Lineas de Transporte: ${state?.selectedRowDataDetail?.val?.transport_name}`}
					></DataTableLines>
				</>
			)}
		</>
	);
};

export default DataTableContainer;

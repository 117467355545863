import "./Map.css";
import "leaflet/dist/leaflet.css";

import {
	useState,
	useEffect,
	useMemo,
	memo,
	useRef,
	useContext,
	useCallback,
} from "react";
import {
	Marker,
	GeoJSON,
	Tooltip,
	TileLayer,
	useMapEvents,
	MapContainer,
	FeatureGroup,
	Circle,
	Polyline,
} from "react-leaflet";

import { customIcon } from "./MapIcons";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import { MapViewContext } from "contexts/MapViewContext";
import { MapContextExporter } from "./MapContentExporter";
import { useLeafletContext } from "@react-leaflet/core";
import { Button, Chip } from "@material-ui/core";

const CenterMapWithLocation = ({ setCenter }) => {
	useEffect(() => {
		map.locate();
	}, []);
	const map = useMapEvents({
		// click: (le) => map.locate(),
		locationfound: ({ latlng }) => {
			let location = { lat: latlng.lat, lng: latlng.lng };
			// setCenter(location);
			//console.log(latlng);
			map.setView(location);
		},
	});

	return null;
};

const useZones = () => {
	const [zones, setZones] = useState([]);
	const data = sessionStorage.getItem("zonas");

	useEffect(() => {
		if (!data) {
			fetch(`${process.env.REACT_APP_API}zonas`)
				.then(async (res) => {
					let zoneArray = await res.json();
					zoneArray.forEach((zone) => {
						zone.poligono.coordinates.forEach((coord) =>
							coord.map((latlng) => latlng.reverse())
						);
					});
					setZones(zoneArray);
					sessionStorage.setItem("zonas", JSON.stringify(zoneArray));
				})
				.catch((err) => console.log(err));
		} else {
			setZones(JSON.parse(data));
		}
	}, [data]);

	return zones;
};
/**
 *
 * @param {*} param0
 * markerSetPositionEnd function to be called after the position of marker is changed, it contains position={lat,lng}
 * markerInitialPosition the initial position for the marker
 * @param {CallableFunction} onClickAZone :when overlayedUVZones activated, it returns an id when a zone is clicked
 * @returns
 */
const Map = ({
	dragabbleMarker,
	markerSetPositionEnd,
	markerInitialPosition,
	overlayedUVZones,
	onClickAZone,
	contributionGeoJSON,
	contributionGeoJSONData,
	isEditable,

	editableAction,
}) => {
	const zones = useZones();
	const markerRef = useRef(null);
	const [center, setCenter] = useState({ lat: -17.7829, lng: -63.181 });
	const [position, setPosition] = useState(
		markerInitialPosition != null ? markerInitialPosition : center
	);

	const eventHandlers = useMemo(
		() => ({
			dragend() {
				const marker = markerRef.current;
				if (marker != null) {
					setPosition(marker.getLatLng());
					markerSetPositionEnd(marker.getLatLng());
				}
			},
		}),
		[markerSetPositionEnd]
	);
	const onEachFeature = (feature, layer, id) => {
		layer.on({
			mouseover: (_) => layer.setStyle({ fillOpacity: 0.1 }),

			mouseout: (_) => layer.setStyle({ fillOpacity: 0.005 }),
		});
	};
	const onEachFetureMemoized = useCallback(onEachFeature, [onClickAZone]);
	const renderZones = () =>
		zones.map(({ id, poligono }) => {
			return (
				<GeoJSON
					eventHandlers={{ click: () => onClickAZone(id) }}
					key={id}
					data={poligono}
					style={{
						weight: 1,
						fill: true,
						opacity: 0.5,
						color: "#0284C7",
						fillOpacity: 0.005,
					}}
					onEachFeature={(feature, layer) => {
						onEachFetureMemoized(feature, layer, id);
					}}
				>
					<Tooltip>
						<strong>{`Zona ${id}`}</strong>
					</Tooltip>
				</GeoJSON>
			);
		});

	const [mapa, setMapa] = useState(null);
	return (
		<MapContainer
			zoom={13}
			className="map"
			center={center}
			scrollWheelZoom={false}
			whenCreated={(d) => {
				console.log(d);
			}}
		>
			<TileLayer
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url={
					"https://api.mapbox.com/styles/v1/cartory/ckn6d7bgr07qk17pnir81rn9r/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY2FydG9yeSIsImEiOiJja242ZHVkbWswZDVoMm5wc3F1Znh5ZXpzIn0.mIs9eqzfLKJKW2TY9sxwaQ"
				}
			/>
			{dragabbleMarker ? (
				<Marker
					ref={markerRef}
					position={position}
					icon={customIcon("balloon")}
					draggable={dragabbleMarker}
					eventHandlers={eventHandlers}
				/>
			) : null}
			{contributionGeoJSON ? null : (
				<CenterMapWithLocation setCenter={setCenter} />
			)}
			
			<TileLayer
				attribution='&copy; <a hrxef="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url={
					"https://api.mapbox.com/styles/v1/cartory/ckn6d7bgr07qk17pnir81rn9r/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY2FydG9yeSIsImEiOiJja242ZHVkbWswZDVoMm5wc3F1Znh5ZXpzIn0.mIs9eqzfLKJKW2TY9sxwaQ"
				}
			/>
			{overlayedUVZones ? renderZones() : null}

			{contributionGeoJSON ? (
				<SelectedContribution data={contributionGeoJSONData} />
			) : null}

			{isEditable ? (
				<FeatureGroup>
					<EditControl
						position="topright"
						onEdited={(e) => {
							console.log(e);
							var drawnItems = new L.FeatureGroup();
							drawnItems.addLayer(e.layers);

							var data = drawnItems.toGeoJSON();
							console.log(contributionGeoJSONData.id);
							editableAction({
								shapes: [
									{
										id: contributionGeoJSONData.id,
										shape_geom: data.features[0].geometry,
									},
								],
							});
							//var blob = new Blob([JSON.stringify(data)], { type: "text/plain" });
							//console.log(blob);
						}}
						draw={{
							rectangle: false,
							polygon: false,
							circle: false,
							circlemarker: false,
							marker: false,
						}}
					/>
					<Polyline
						fillColor={"#F2F2F2"}
						positions={contributionGeoJSONData.shape_geom.coordinates.map(
							(x) => L.GeoJSON.coordsToLatLng(x)
						)}
					></Polyline>
				</FeatureGroup>
			) : null}
		</MapContainer>
	);
};

const SelectedContribution = ({ data }) => {
	const context = useLeafletContext();
	const geoJSONRef = useRef();
	const flyToGeoJSON = () => {
		const geoJSONBounds = geoJSONRef.current.getBounds();
		try {
			geoJSONBounds.getNorth();
			geoJSONBounds.getWest();
			context?.map.flyToBounds(geoJSONBounds);
		} catch (error) {
			alert(
				"DATOS CORRUPTOS, SE ACONSEJA BORRAR O RECHAZAR ESTA CONTRIBUCION"
			);
		}
	};
	useEffect(() => {
		flyToGeoJSON();
	}, [context]);

	return (
		<GeoJSON
			ref={geoJSONRef}
			key={data?.id}
			data={data?.shape_geom}
			style={{
				weight: 5,
				fill: true,
				opacity: 1,
				color: "rgb(169, 216, 0)",
				fillOpacity: 0.005,
			}}
			// onEachFeature={(_, layer) => {
			// 	layer.on("mouseover", (_) => layer.setStyle({ fillOpacity: 0.1 }))
			// 	layer.on("mouseout", (_) => layer.setStyle({ fillOpacity: 0.005 }))
			// }}
		>
			<Tooltip>
				<strong>{`Contribucion ${data?.id}`}</strong>
			</Tooltip>
		</GeoJSON>
	);
};
export default memo(Map);

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import { LineAPI } from "api/line.api";
import { PoiAPI } from "api/poi.api";
import { TransportAPI } from "api/transport.api";
import DataTableCustom from "components/Material/DataTables/DataTableCustom";
import { StateSelect } from "components/Selects/StateSelect";
import { useGlobalDialog } from "hooks/useGlobalDialog";
import Swal from "sweetalert2";
import { LineAlertMap } from "./AlertMap";

const cols = ["id", "line_contributionstatus", "trip_id"];

export const DataTableLines = ({ rows, reloadPage, title }) => {
	const dialog = useGlobalDialog();

	const handlers = {
		onEdit: async (rowData) => {
			dialog.showWidget(() => (
				<LineAlertMap
					id={rowData.id}
					tripId={rowData.trip_id}
					selectedContribution={rowData?.Shape}
					onClickSave={() => {
						reloadPage();
						dialog.hide();
					}}
				/>
			));
		},
		onView: async (rowData) => {
			dialog.showWidget(() => (
				<LineAlertMap
					transportName={rowData.id}
					selectedContribution={rowData?.Shape}
				/>
			));
			//  setSelectedContribution(rowData?.Lines?.[0]?.Shape)
			//console.log(rowData?.Lines?.[0]?.Shape)
		},
	};
	const onDelete = async (rowData) => {
		// const res = await PoiAPI.destroy(rowData.id);
		// reloadPage();
	};
	const callbacks = {
		/**
		 *
		 * @param {*} currentItem the object to be afected
		 * @param {*} contributionStateNamed  the stateNamed of the contribution
		 */
		changeContributionStateWithAPI: async (
			currentItem,
			contributionStateNamed,
			valueChanged
		) => {
			const res = await LineAPI.update(currentItem.id, {
				line_contributionstatus: valueChanged,
			});
			console.log(res);
			reloadPage();
			Swal.fire("Estado Cambiado Exitosamente");
		},
	};
	const actionColumnFunctions = {
		stateContribution: ({ colName, obj }) => {
			if (colName == "line_contributionstatus") {
				obj.render = (data) => {
					//Data is the current object
					return (
						<StateSelect
							initialContributionState={data[colName]}
							onChangeStateCallback={(
								contributionStateNamed,
								valueChanged
							) => {
								callbacks.changeContributionStateWithAPI(
									data,
									contributionStateNamed,
									valueChanged
								);
							}}
						></StateSelect>
					);
				};
			}
		},
	};
	const columns = [
		{ title: "id", field: "id" },
		{ title: "transport_name", field: "transport_name" },
		{
			title: "transport_contributionstatus",
			field: "transport_contributionstatus",
			render: (rowData) => (
				<StateSelect
					initialContributionState={rowData}
					onChangeStateCallback={(
						contributionStateNamed,
						valueChanged
					) => {
						callbacks.changeContributionStateWithAPI(
							rowData,
							contributionStateNamed,
							valueChanged
						);
					}}
				></StateSelect>
			),
		},
	];
	return (
		<>
			<DataTableCustom
				MaterialTableProps={{
					title,
					key: "MaterialLugar",
					options: {
						filtering: true,
					},
				}}
				cols={cols}
				rowsData={rows}
				actionColumn
				actionColumnFunctions={handlers}
				customColumnRenders={[actionColumnFunctions.stateContribution]}
			/>
		</>
	);
};

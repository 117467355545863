import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { CloudUpload, Delete, Edit } from "@material-ui/icons";
import { TransportAPI } from "api/transport.api";
import { ImageUploader } from "components/Material/Inputs/ImageUploader/ImageUploader";
import { mapToImageUploaderObject } from "components/Material/Inputs/ImageUploader/ImageUploader.utils";
import { useGlobalDialog } from "hooks/useGlobalDialog";
import { useStateNamed } from "hooks/useStateNamed";
import React from "react";
import { useEffect } from "react";
import ReactImageUploading from "react-images-uploading";
import {
	getStateNamedStatelesJSON,
	handleFormChangeStateNamed,
} from "utils/formUtils";

export const TransportBasicDetails = ({
	attributes = { pictures: "", name: "", routeId: "" },
	onClickSave = () => {},
}) => {
	const globalDialog = useGlobalDialog();
	const transportFormData = {
		picture: useStateNamed(
			mapToImageUploaderObject(attributes.pictures, {
				id: "id",
				pictureUrl: "pic_url",
			})
		),
		name: useStateNamed(attributes.name),
		routeId: useStateNamed(attributes.routeId),
	};
	const handlers = {
		onClickSave: async () => {
			//TODO: consume the api of transport edition

			if (attributes.id) {
				const transportToUpdate = {
					id: attributes.id,
					pictures: transportFormData.picture.val,
					...getStateNamedStatelesJSON(transportFormData),
				};
				await TransportAPI.updateV3(transportToUpdate);
			}
			globalDialog.hide();
			onClickSave();
		},
	};
	return (
		<>
			<DialogTitle id="alert-dialog-title">
				{"Editar Transporte"}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item sm={6}>
						<TextField
							value={transportFormData.name?.val}
							label="Nombre"
							name="name"
							onChange={(e) =>
								handleFormChangeStateNamed(e, transportFormData)
							}
						/>
					</Grid>
					<Grid item sm={6}>
						<TextField
							value={transportFormData.routeId?.val}
							label="Ruta GTFS (route_id)"
							name="routeId"
							onChange={(e) =>
								handleFormChangeStateNamed(e, transportFormData)
							}
						/>
					</Grid>
					<Grid item sm={12}>
						<ImageUploader
							images={transportFormData.picture.val}
							maxNumber={1}
							onChange={transportFormData.picture.set}
							multiple={false}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handlers.onClickSave}>
					{"Guardar"}
				</Button>
			</DialogActions>
		</>
	);
};

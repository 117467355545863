import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { url } from "api/api.utils";
import { DividedDateTimePicker } from "components/DatePickers/DividedDateTimePicker";
import MapView from "components/Map/MapView";
import "date-fns";
import MaterialTable from "material-table";
import { useEffect, useMemo, useState } from "react";
import { options, tableIcons } from "../../../utils/tableSettings";

const cols = ["id", "fechaConsulta", "latLng", "isWeb"];

const Report = () => {
	let model = useMemo(() => ({ init: new Date(), end: new Date() }), []);
	const [query, setQuery] = useState(false);
	const [views, setViews] = useState([]);

	useEffect(() => {
		if (query) {
			fetch(
				`${url}consultas?init=${model.init.getTime()}&end=${model.end.getTime()}`
			)
				.then(async (res) => {
					setQuery(false);
					setViews(await res.json());
				})
				.catch((err) => {
					setQuery(false);
					console.log(err);
				});
		}
	}, [query, model]);

	return (
		<>
			<div className="flex flex-wrap">
				<div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<div className="relative flex flex-row flex-wrap min-w-0 w-full mb-6 shadow-lg rounded-lg bg-white p-5">
							<h1 className="w-full">Seleccionar Fecha</h1>
							<DividedDateTimePicker init model={model} />
							<DividedDateTimePicker model={model} />
							<TextField
								fullWidth
								type="number"
								variant="outlined"
								label="Cantidad Máxima"
								defaultValue={10000}
								onInput={({ target }) =>
									(model["limit"] = target.value)
								}
							/>
							<button
								type="submit"
								style={{ marginTop: 10 }}
								className="cruzero-bg-light-green-01 text-white active:bg-green-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none"
								onClick={() => {
									if (model.end.getTime() > model.init.getTime()) {
										setQuery(true);
									} else {
										alert("RANGO INVÁLIDO");
									}
								}}
							>
								Consultar
							</button>
						</div>
					</MuiPickersUtilsProvider>
				</div>
				<div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
					<MapView views={views} />
				</div>
				<div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4 mt-10">
					<MaterialTable
						isLoading={query}
						title="Visitas Realizadas"
						columns={cols.map((col, index) => ({
							key: index,
							field: col,
							title: col,
						}))}
						data={views}
						options={options}
						icons={tableIcons}
					/>
				</div>
			</div>
		</>
	);
};

export default Report;

import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";


export const ModalAlertDialog = ({
	children,
	title,
	open,
	closeButton,
	saveButton,
	okButton,
	closeButtonText,
	saveButtonText,
	okButtonText,
	onCloseButtonClick,
	onOkButtonClick,
	onSaveButtonClick
}) => {
	const handleClose = () => {

	};

	return (
		<>
			<Dialog maxWidth={'md'}
				fullWidth={true}
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{title}
				</DialogTitle>
				<DialogContent>{children}</DialogContent>
				<DialogActions>
					{closeButton ? (
						<Button
							onClick={
								onCloseButtonClick ? onCloseButtonClick : handleClose
							}
							color="primary"
						>
							{closeButtonText ? closeButtonText : "Cerrar"}
						</Button>
					) : null}

					{saveButton ? (
						<Button
							onClick={
								onSaveButtonClick ? onSaveButtonClick : handleClose
							}

							variant="contained"
							color="primary"
						>
							{saveButtonText ? saveButtonText : "Guardar"}
						</Button>
					) : null}

					{okButton ? (
						<Button

							onClick={onOkButtonClick ? onOkButtonClick : handleClose}

							autoFocus
						>
							{okButtonText ? okButtonText : "Listo"}
						</Button>
					) : null}
				</DialogActions>
			</Dialog>
		</>
	);
};

import { AccessTime } from "@material-ui/icons"
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers"
import { useDate } from "hooks/useDate"

export const DividedDateTimePicker = ({ init = false, model }) => {
    const { date, handleDate } = useDate(init, model)

    return (
        <>
            <KeyboardDatePicker
                margin="normal"
                variant="inline"
                format="dd/MM/yyyy"
                value={date}
                onChange={handleDate}
                style={{ width: '50%' }}
                label={`Fecha ${init ? 'Inicial' : 'Final'}`}
            />
            <KeyboardTimePicker
                className="w-full"
                margin="normal"
                variant="inline"
                value={date}
                onChange={handleDate}
                style={{ width: '50%' }}
                keyboardIcon={<AccessTime />}
                label={`Hora ${init ? 'Inicial' : 'Final'}`}
            />
        </>
    )
}
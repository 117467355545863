import { useStateNamed } from "hooks/useStateNamed";
import { createContext } from "react";

export const MapViewContext = createContext({
	mapContext: {
		val: { map: null, pane: "", layerContainer: null, popupContainer: null },
		set: () => {},
	},
});

export const MapViewContextProvider = ({children}) => {
    const contextState = {
		mapContext: useStateNamed({ map: null, pane: "", layerContainer: null, popupContainer: null }),
		
	};
	return <MapViewContext.Provider value={contextState}>{children}</MapViewContext.Provider>;
};

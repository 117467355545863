import "leaflet/dist/leaflet.css";
import { memo, useEffect, useState } from "react";
import { MapContainer, TileLayer, useMapEvents, } from "react-leaflet";
import {useLeafletContext} from '@react-leaflet/core'
import "react-leaflet-markercluster/dist/styles.min.css";
import "./Map.css";
import { useMapViewExporter } from "hooks/useMapViewExporter";
import { MapContextExporter } from "./MapContentExporter";

const MapEvents = ({ setCenter }) => {
	const map = useMapEvents({
		locationfound: ({ latlng }) => {
			let location = { lat: latlng.lat, lng: latlng.lng };

			setCenter(location);
			map.setView(location);
		},
	});
	map.locate();
	return null;
};


const MapView = ({ views = [], destinies = [] ,children}) => {
	const [center, setCenter] = useState({ lat: -17.7829, lng: -63.181 });
    
	console.log(views);
	return (
		<MapContainer maxZoom={20} zoom={13} className="map" center={center}>
            {children}
			<MapContextExporter/>
			<MapEvents setCenter={setCenter} />
			<TileLayer
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url="https://api.mapbox.com/styles/v1/cartory/ckn6d7bgr07qk17pnir81rn9r/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY2FydG9yeSIsImEiOiJja242ZHVkbWswZDVoMm5wc3F1Znh5ZXpzIn0.mIs9eqzfLKJKW2TY9sxwaQ"
			/>
			{/* <MarkerClusterGroup
                animate
                maxClusterRadius={150}
                spiderfyOnMaxZoom={true}
            > */}
			{/* {views.map(view => {
                    return <CircleMarker
                        center={JSON.parse(view.latLng)}
                        color={view.isWeb ? "red" : "blue"}
                    >
                        <Tooltip>
                            {view.isWeb ? <Language /> : <Smartphone />}
                            <strong>{` ${view.fechaConsulta.split('T')[0]}`}</strong>
                        </Tooltip>
                    </CircleMarker>
                })} */}
			{/* {destinies.map(row => {
                    return <>
                        <CircleMarker
                            center={JSON.parse(row.origen)}
                            color="yellow"
                        >
                            <Tooltip>
                                <Fingerprint />
                                <strong>{` ${row.id}`}</strong>
                                <br />
                                <Schedule />
                                <strong>{` ${row.fechaConsulta.split('T')[0]}`}</strong>
                            </Tooltip>
                        </CircleMarker>
                        <CircleMarker
                            center={JSON.parse(row.origen)}
                            color="green"
                        >
                            <Tooltip>
                                <Fingerprint />
                                <strong>{` ${row.id}`}</strong>
                                <br />
                                <Flag />
                                <strong>{` ${row.fechaConsulta.split('T')[0]}`}</strong>
                            </Tooltip>
                        </CircleMarker>
                    </>
                })} */}
			{/* </MarkerClusterGroup> */}
		</MapContainer>
	);
};

export default MapView;

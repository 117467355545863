/* eslint-disable react-hooks/rules-of-hooks */
import { useHistory } from "react-router"
import { authAPI } from "api/auth.api"


const Login = ({ user = {} }) => {
  const history = useHistory()

  const onInput = ({ target: { name, value } }) => user[name] = value
  const onSubmit = async e => {
    e.preventDefault()
    await authAPI.signIn({ user, history })
  }

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
            <div className="rounded-t mb-0 px-6 py-6">
              <div className="text-center mb-3">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Iniciar Sesión
                </h6>
              </div>
              <hr className="mt-0 border-b-1 border-blueGray-300" />
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={onSubmit}>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="grid-password"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >Email</label>
                  <input
                    required
                    type="email"
                    name="correo"
                    placeholder="Email"
                    onInput={onInput}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >Password</label>
                  <input
                    required
                    type="password"
                    onInput={onInput}
                    name="contrasenia"
                    placeholder="Password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
                <div>
                  <label className="inline-flex justify-around cursor-pointer items-center">
                    <input
                      type="checkbox"
                      id="customCheckLogin"
                      className="border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                    />
                    <span className="ml-2 text-sm font-semibold text-blueGray-600">Recuerdame</span>
                  </label>
                </div>

                <div className="text-center mt-6">
                  <button
                    type="submit"
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  >Iniciar Sesión</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
import { url } from "./api.utils";

export const authAPI = {
    
/**
 * @param {Object} user 
 * @param {History} history useHistory
 */
	signIn: async ({ user, history }) => {
		console.log(url);
		let res = await fetch(`${url}signin`, {
			method: "POST",
			headers: { "Content-Type": "Application/json" },
			body: JSON.stringify(user),
		});

		const data = await res.json();
        console.log(data);
		switch (res.status) {
			case 200:
				sessionStorage.setItem("user", JSON.stringify(data));
				history.push("/admin");
				break;
			case 401:
				sessionStorage.clear();
				alert(data["error"]);
                
				break;
			default:
				alert(data["error"]);
				break;
		}
	},
};
